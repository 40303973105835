import {Api} from './apiDefinition.js'

//Research
export const postResearchResultsGenerate = async ({payload}) => {
  const {data} = await Api.post('/agent/research/results/generate', payload)
  return data
}
export const getResearchInstructions = async ({params}) => {
  const {data} = await Api.get('/agent/research/instructions', {params})
  return data
}
export const postResearchInstructions = async ({payload}) => {
  const {data} = await Api.post('/agent/research/instructions', payload)
  return data
}
export const patchResearchInstructions = async ({params, payload}) => {
  const {data} = await Api.patch('/agent/research/instructions', payload, {params})
  return data
}
export const deleteResearchInstructions = async ({params}) => {
  await Api.delete('/agent/research/instructions', {params})
}
export const getResearchResults = async ({params}) => {
  const {data} = await Api.get('/agent/research/results', {params})
  return data
}
export const postResearchResults = async ({payload}) => {
  const {data} = await Api.post('/agent/research/results', payload)
  return data
}
export const patchResearchResults = async ({params, payload}) => {
  const {data} = await Api.patch('/agent/research/results', payload, {params})
  return data
}
export const deleteResearchResults = async ({params}) => {
  await Api.delete('/agent/research/results', {params})
}

//Leads
export const postLeadsRank = async ({payload}) => {
  const {data} = await Api.post('/agent/leads/rank', payload)
  return data
}

//Messages
export const getSupportedLanguages = async () => {
  const {data} = await Api.get('/agent/message/supported-languages')
  return data
}
export const getMessageResults = async ({params}) => {
  const {data} = await Api.get('/agent/message/results', {params})
  return data
}
export const postMessageResults = async ({payload}) => {
  const {data} = await Api.post('/agent/message/results', payload)
  return data
}
export const patchMessageResults = async ({params, payload}) => {
  const {data} = await Api.patch('/agent/message/results', payload, {params})
  return data
}
export const deleteMessageResults = async ({params}) => {
  await Api.delete('/agent/message/results', {params})
}
export const postMessageResultsGenerate = async ({payload}) => {
  const {data} = await Api.post('/agent/message/results/generate', payload)
  return data
}

//Message instructions
export const getMessageInstructions = async ({params}) => {
  const {data} = await Api.get('/agent/message/instructions', {params})
  return data
}
export const postMessageInstructions = async ({payload}) => {
  const {data} = await Api.post('/agent/message/instructions', payload)
  return data
}
export const patchMessageInstructions = async ({params, payload}) => {
  const {data} = await Api.patch('/agent/message/instructions', payload, {params})
  return data
}
export const deleteMessageInstructions = async ({params}) => {
  await Api.delete('/agent/message/instructions', {params})
}
