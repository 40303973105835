import {atom} from 'recoil'
import {sessionStorageEffect} from './effects.js'

//ATOMS
export const audienceMetaData = atom({
  key: 'audienceMetaData',
  default: {
    label: '',
    companiesInAudience: undefined,
    peopleInAudience: undefined,
    createdAt: '',
    updatedAt: '',
    usedInCampaign: undefined
  },
  effects: [sessionStorageEffect('audienceMetaData')]
})

export const companySearchSettings = atom({
  key: 'companySearchSettings',
  default: {
    employeesValues: [],
    employeesSign: 'equals',
    employeesOperator: 'or',
    continentValues: [],
    continentSign: 'equals',
    continentOperator: 'or',
    countryValues: [],
    countrySign: 'equals',
    countryOperator: 'or',
    stateValues: [],
    stateSign: 'equals',
    stateOperator: 'or',
    countyValues: [],
    countySign: 'equals',
    countyOperator: 'or',
    cityValues: [],
    citySign: 'equals',
    cityOperator: 'or',
    revenueValues: [],
    revenueSign: 'equals',
    revenueOperator: 'or',
    industryValues: [],
    industrySign: 'equals',
    industryOperator: 'or',
    industriesValues: [],
    industriesSign: 'equals',
    industriesOperator: 'or',
    codeNaicsValues: [],
    codeNaicsSign: 'exactEquals',
    codeNaicsOperator: 'or',
    technologyValues: [],
    technologySign: 'equals',
    technologyOperator: 'or',
    linkedinIdValues: [],
    linkedinIdSign: 'notEquals',
    linkedinIdOperator: 'or',
    domainValues: [],
    domainSign: 'notEquals',
    domainOperator: 'or',
    nameValues: [],
    nameSign: 'equals',
    nameOperator: 'or'
  },
  effects: [sessionStorageEffect('companySearchSettingsNew')]
})

export const personSearchSettings = atom({
  key: 'personSearchSettings',
  default: {
    searchOrganizations: [],
    countryValues: [],
    seniorityValues: [],
    departmentValues: [],
    keywordValues: []
  },
  effects: [sessionStorageEffect('personSearchSettings')]
})
