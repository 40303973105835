import {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {Container, Col, Form, Stack} from 'react-bootstrap'
import {Paper} from '@mui/material'

import {InfoPageTemplate} from '../../templates/InfoPageTemplate.js'

export const Pricing = () => {
  const navigate = useNavigate()

  const [isAnnualPricing, setIsAnnualPricing] = useState(true)

  const contentColClass = 'd-flex flex-column justify-content-center align-items-center'
  const contentColBreakpoints = {
    xxl: 8,
    xl: 10,
    md: 11,
    xs: 12
  }

  const starterPackage = [
    {label: 'Branding - Import your email signature'},
    {label: 'AI Message Builder'},
    {label: 'AI Driven Lead Sorting'},
    {label: 'Email integration (Gmail/Outlook)'},
    {label: 'Email support'},
    {label: 'Campaign CSV Export'},
    {label: 'Email Bounce/Response Tracking'},
    {label: '1 Active Campaign'}
  ]

  const standardPackage = [
    {label: 'All in Starter, plus:'},
    {label: 'Custom Campaign Analytics'},
    {label: 'Email & Phone support'},
    {label: '3 Active Campaigns'}
  ]
  const premiumPackage = [
    {label: 'All in Standard, plus:'},
    {label: 'Priority support'},
    {label: 'Advanced API Access'},
    {label: 'Custom Email Configuration Set-up'},
    {label: 'Unlimited Active Campaigns'}
  ]

  const subscriptions = [
    {
      label: 'Starter',
      priceMonthly: 90,
      credits: 350,
      itemsIncluded: starterPackage
    },
    {
      label: 'Standard',
      priceMonthly: 290,
      credits: 1300,
      itemsIncluded: standardPackage
    },
    {
      label: 'Premium',
      priceMonthly: 690,
      credits: 3200,
      itemsIncluded: premiumPackage
    }
  ]

  const calculateDiscountedPrice = (priceMonthly) => {
    return Math.ceil(priceMonthly * 0.8)
  }

  return (
    <InfoPageTemplate>
      <Container
        id='pricing'
        fluid
        className='background--p-light d-flex justify-content-center align-items-center'
        style={{paddingTop: '13rem', paddingBottom: '13rem'}}
      >
        <Col
          {...contentColBreakpoints}
          className={contentColClass}
        >
          <h1 className='d-text__font--heading d-text__placement--center color--p-dark'>
            Pricing
          </h1>
          <h3
            style={{padding: '1rem'}}
            className='d-text__font--subheading d-text__placement--center color--s-dark'
          >
            Save on annual plans
          </h3>
          <Stack
            direction='horizontal'
            gap={0}
            style={{alignSelf: 'center', padding: '3rem'}}
            className='clickable'
            onClick={() => setIsAnnualPricing(!isAnnualPricing)}
          >
            <Form.Check
              type='switch'
              id='annual-pricing-switch'
              className='h4 clickable'
              checked={isAnnualPricing}
            />
            {isAnnualPricing ? (
              <h3 className='d-text__font--subheading d-text__placement--center color--accent'>
                Annual Billing
              </h3>
            ) : (
              <h3 className='d-text__font--subheading d-text__placement--center color--p-dark'>
                Monthly Billing
              </h3>
            )}
          </Stack>
          <div className='d-flex flex-wrap justify-content-center'>
            {subscriptions.map((sub, idx) => (
              <Paper
                key={idx}
                className='background--s-light clickable transition-medium t-scale--large t-background--p-light t-border-color--accent'
                onClick={() => {
                  navigate('/demo#schedule')
                }}
                style={{
                  padding: '1rem',
                  width: '18rem',
                  margin: '0.8rem',
                  border: '1px solid var(--secondary-light)'
                }}
                elevation={1}
              >
                <h2 className='d-text__font--heading d-text__placement--center color--s-dark'>
                  {sub.label}
                </h2>
                <hr />
                <h4
                  className='d-text__font--subheading color--p-dark'
                  style={{textAlign: 'center'}}
                >
                  {isAnnualPricing
                    ? `€ ${calculateDiscountedPrice(sub.priceMonthly)}`
                    : `€ ${sub.priceMonthly}`}
                </h4>
                <p
                  className='d-text__font--subheading color--s-dark'
                  style={{textAlign: 'center'}}
                >
                  {`per user | per month`}
                </p>
                <p
                  className='d-text__font--body color--s-dark'
                  style={{textAlign: 'center'}}
                >
                  {`billed`}

                  <span style={{position: 'relative', color: 'var(--accent)'}}>
                    {isAnnualPricing ? ` annually` : ` monthly`}
                  </span>
                </p>
                <hr />
                <p
                  className='d-text__font--body color--p-dark'
                  style={{textAlign: 'center'}}
                >
                  {`credits: ${sub.credits}`}
                </p>
                <p
                  className='d-text__font--subheading color--s-dark'
                  style={{textAlign: 'center'}}
                >
                  {`per month`}
                </p>
                <hr />
                {sub.itemsIncluded.map((item, idx) => (
                  <p
                    key={idx}
                    className='d-text__font--body color--s-dark d-flex align-items-center'
                    style={{textAlign: 'start'}}
                  >
                    <span
                      className={'material-symbols-outlined'}
                      style={{
                        color: 'yellowgreen',
                        marginRight: '0.5rem',
                        fontSize: '1.44rem',
                        lineHeight: '1.44rem',
                        height: '1.44rem',
                        verticalAlign: 'middle'
                      }}
                    >
                      {'check'}
                    </span>

                    {item.label}
                  </p>
                ))}
              </Paper>
            ))}
          </div>
          <h5
            className='d-text-__font--subheading d-text__placement--center color--p-dark'
            style={{padding: '4rem'}}
          >
            Prices listed are excluding VAT.
          </h5>
        </Col>
      </Container>
    </InfoPageTemplate>
  )
}
