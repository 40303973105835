import {useEffect, useState} from 'react'
import {Paper} from '@mui/material'
import {Collapse} from 'react-bootstrap'

import * as AgentServerState from '../../hooks/server/useAgentServerState.js'
import * as UserServerState from '../../hooks/server/useUserServerState.js'
import * as AudiencesServerState from '../../hooks/server/useAudiencesServerState.js'
import {usePostCampaignEmailsSend} from '../../hooks/server/useCampaignsServerState.js'

import {
  useInputMessagesAtom,
  useGeneratedMessageAtom,
  useMessageInstructionsAtom,
  useEnrichmentPersonUrlAtom,
  useEnrichmentCompanyUrlAtom
} from '../../hooks/client/useAgentClientState.js'

import {SidebarTemplate} from '../templates/SidebarTemplate.js'

import {MessageInstructionsInput} from '../organisms/MessageInstructionsInput.js'
import {MessageInstructionsOutput} from '../organisms/MessageInstructionsOutput.js'

import {UserCreditWarningModal} from '../molecules/UserCreditWarningModal.js'
import {UserProfileWarningInfoModal} from '../molecules/UserProfileWarningInfoModal.js'

import {successToast} from '../atoms/Toasts.js'

import {useDebounce, validateField, isValidUrl} from '../../utils/hooks.js'
import {buildMessageThread} from '../../utils/helpers.js'

export const MessageBuilder = () => {
  //CLIENT GLOBAL STATES
  const {
    messageInstructionsAtom,
    setMessageInstructionsAtom,
    setMessageInstructionsKey
  } = useMessageInstructionsAtom()
  const [generatedMessage, setGeneratedMessage] = useGeneratedMessageAtom()
  const {inputMessages, setInputMessages} = useInputMessagesAtom()

  const [enrichmentPersonUrl, setEnrichmentPersonUrl] = useEnrichmentPersonUrlAtom()
  const [enrichmentCompanyUrl, setEnrichmentCompanyUrl] = useEnrichmentCompanyUrlAtom()

  //CLIENT LOCAL STATES
  const [showProfileInfoModal, setShowProfileInfoModal] = useState(false)
  const [showCreditInfoModal, setShowCreditInfoModal] = useState(false)
  const [debouncedEnrichmentPersonUrl, enrichmentPersonUrlIsDebouncing] = useDebounce(
    enrichmentPersonUrl,
    1000
  )
  const [debouncedEnrichmentCompanyUrl, enrichmentCompanyUrlIsDebouncing] = useDebounce(
    enrichmentCompanyUrl,
    1000
  )
  const modelToEnforce = 'gpt-4o-mini-2024-07-18'

  // QUERY STATES
  const {data: messageInstructions, fetchStatus: messageInstructionsFetchStatus} =
    AgentServerState.useGetMessageInstructions()

  const {data: messages, fetchStatus: messagesFetchStatus} =
    AgentServerState.useGetMessageResults()

  const {data: userAccessToken, isError: userAccessTokenIsError} =
    UserServerState.useGetUserAccessToken({params: {dry_run: true}})

  const {data: userProfile, fetchStatus: userProfileFetchStatus} =
    UserServerState.useGetUserProfile()

  const {data: supportedLanguages, fetchStatus: supportedLanguagesFetchStatus} =
    AgentServerState.useGetSupportedLanguages()

  const {data: userCredits, fetchStatus: userCreditsFetchStatus} =
    UserServerState.useGetUserCredit()

  const {
    data: enrichmentPerson,
    status: enrichmentPersonStatus,
    fetchStatus: enrichmentPersonFetchStatus
  } = AudiencesServerState.useGetEnrichmentPerson({
    enabled: isValidUrl(debouncedEnrichmentPersonUrl),
    params: {url: debouncedEnrichmentPersonUrl}
  })

  const {
    data: enrichmentPersonPhoneNumbers,
    status: enrichmentPersonPhoneNumbersStatus,
    fetchStatus: enrichmentPersonPhoneNumbersFetchStatus,
    refetch: enrichmentPersonPhoneNumbersRefetch
  } = AudiencesServerState.useGetEnrichmentPersonPhoneNumbers({
    enabled: false,
    params: {
      linkedin_identifier: debouncedEnrichmentPersonUrl?.match(/\/in\/([^/?#]+)/i)?.[1]
    }
  })

  const {
    data: enrichmentCompany,
    status: enrichmentCompanyStatus,
    fetchStatus: enrichmentCompanyFetchStatus
  } = AudiencesServerState.useGetEnrichmentCompany({
    enabled: isValidUrl(debouncedEnrichmentCompanyUrl),
    params: {url: debouncedEnrichmentCompanyUrl}
  })

  // MUTATION STATES
  const {mutate: postCampaignEmailsSend, status: postCampaignEmailsSendStatus} =
    usePostCampaignEmailsSend()

  const {mutate: postMessageInstructions, status: postMessageInstructionsStatus} =
    AgentServerState.usePostMessageInstructions()

  const {mutate: patchMessageInstructions, status: patchMessageInstructionsStatus} =
    AgentServerState.usePatchMessageInstructions()

  const {mutate: postMessageResults, status: postMessageStatus} =
    AgentServerState.usePostMessageResults()

  const {mutate: postMessageResultsGenerate, status: postMessageGenerateStatus} =
    AgentServerState.usePostMessageResultsGenerate()

  //DERIVED LOCAL STATES
  const firstNameError = validateField(userProfile?.first_name, 'First Name')
  const companyNameError = validateField(
    messageInstructionsAtom?.companyName,
    'Company Name'
  )
  const intentionError = validateField(messageInstructionsAtom?.intention, 'Intention')
  const lastNameError = validateField(userProfile?.last_name, 'Last Name')
  const preferencesErrors = messageInstructionsAtom?.preferences?.some((p) =>
    validateField(p, '')
  )
  const titleError = validateField(messageInstructionsAtom?.title, 'Title')
  const valuePropositionError = validateField(
    messageInstructionsAtom?.valueProposition,
    'Value Proposition'
  )
  const inputMessagesAreCorrect =
    messageInstructionsAtom?.outreachStage === 'initial_message'
      ? true
      : Array.isArray(inputMessages) &&
        inputMessages?.length > 0 &&
        inputMessages.every((msg) => typeof msg === 'string' && msg.length === 36)

  const profileInformationInputIsCorrect =
    !firstNameError &&
    !lastNameError &&
    !titleError &&
    !companyNameError &&
    !valuePropositionError
  const messageInformationInputIsCorrect =
    !!enrichmentPersonUrl && !!enrichmentCompanyUrl && inputMessagesAreCorrect
  const preferencesInformationInputIsCorrect = !intentionError && !preferencesErrors
  const allInputInformationIsCorrect =
    profileInformationInputIsCorrect &&
    messageInformationInputIsCorrect &&
    preferencesInformationInputIsCorrect

  //HANDLERS
  const handlePostEmailSend = (recipientEmail) => {
    postCampaignEmailsSend({
      params: {
        access_token: userAccessToken.access_token,
        provider: userAccessToken.provider
      },
      payload: {
        email_body: generatedMessage?.body,
        email_subject: generatedMessage?.header,
        recipient_email: recipientEmail
      }
    })
  }

  const handleLoadMessageInstructions = (messageInstructionsId) => {
    const _messageInstructions = messageInstructions.find(
      (t) => t?.message_instructions_id === messageInstructionsId
    )
    setMessageInstructionsAtom({
      messageInstructionsId: _messageInstructions.message_instructions_id,
      label: _messageInstructions.label,
      language: _messageInstructions.language,
      title: _messageInstructions.title,
      companyName: _messageInstructions.company_name,
      valueProposition: _messageInstructions.value_proposition,
      intention: _messageInstructions.intention,
      preferences: _messageInstructions.message_preferences,
      outreachMethod: _messageInstructions.outreach_method,
      outreachStage: _messageInstructions.outreach_stage,
      messageSignature: _messageInstructions.message_signature
    })
    successToast(`Message instructions "${_messageInstructions.label}" loaded!`)
  }

  const handlePostMessageInstructions = () => {
    postMessageInstructions(
      {
        payload: {
          company_name: messageInstructionsAtom.companyName,
          first_name: userProfile.first_name,
          intention: messageInstructionsAtom.intention,
          label: messageInstructionsAtom.label,
          language: messageInstructionsAtom.language,
          last_name: userProfile.last_name,
          outreach_method: messageInstructionsAtom.outreachMethod,
          outreach_stage: messageInstructionsAtom.outreachStage,
          title: messageInstructionsAtom.title,
          message_preferences: messageInstructionsAtom.preferences,
          value_proposition: messageInstructionsAtom.valueProposition,
          temperature: 0,
          model_name: modelToEnforce,
          message_signature: messageInstructionsAtom.messageSignature
        }
      },
      {
        onSuccess: () => {
          setMessageInstructionsKey('label', null)
        }
      }
    )
  }

  const handlePatchMessageInstructions = () => {
    patchMessageInstructions({
      params: {
        message_instructions_id: messageInstructionsAtom.messageInstructionsId
      },
      payload: {
        company_name: messageInstructionsAtom.companyName,
        first_name: userProfile.first_name,
        intention: messageInstructionsAtom.intention,
        label: messageInstructionsAtom.label,
        language: messageInstructionsAtom.language,
        last_name: userProfile.last_name,
        outreach_method: messageInstructionsAtom.outreachMethod,
        outreach_stage: messageInstructionsAtom.outreachStage,
        title: messageInstructionsAtom.title,
        message_preferences: messageInstructionsAtom.preferences,
        value_proposition: messageInstructionsAtom.valueProposition,
        temperature: 0,
        model_name: modelToEnforce,
        message_signature: messageInstructionsAtom.messageSignature
      }
    })
  }

  const handlePostMessageGenerate = () => {
    let payload = {
      language: messageInstructionsAtom.language,
      outreach_method: messageInstructionsAtom.outreachMethod,
      outreach_stage: messageInstructionsAtom.outreachStage,
      sender_employer_name: messageInstructionsAtom.companyName,
      sender_first_name: userProfile.first_name,
      sender_intention: messageInstructionsAtom.intention,
      sender_last_name: userProfile.last_name,
      sender_preferences: messageInstructionsAtom.preferences,
      sender_title: messageInstructionsAtom.title,
      sender_value_proposition: messageInstructionsAtom.valueProposition,
      recipient_summary: enrichmentPerson.cleaned_summary,
      recipient_employer_summary: enrichmentCompany.cleaned_summary,
      message_signature: messageInstructionsAtom.messageSignature,
      temperature: 0,
      model_name: modelToEnforce
    }
    if (
      messageInstructionsAtom.outreachStage === 'follow_up_message' &&
      inputMessages?.length > 0
    ) {
      payload.message_thread = buildMessageThread({
        inputMessageIds: inputMessages,
        messages: messages,
        sender: userProfile.first_name,
        recipient: enrichmentPerson?.first_name
      })
    }

    postMessageResultsGenerate(
      {
        payload: payload
      },
      {
        onSuccess: (response) => {
          setGeneratedMessage({
            header: response.message_header,
            body: response.message_body,
            bodyRaw: response.message_body_raw
          })
        }
      }
    )
  }

  const handlePostMessage = (label) => {
    postMessageResults({
      payload: {
        language: messageInstructionsAtom.language,
        temperature: 0,
        model_name: modelToEnforce,
        outreach_method: messageInstructionsAtom.outreachMethod,
        outreach_stage: messageInstructionsAtom.outreachStage,
        sender_employer_name: messageInstructionsAtom.companyName,
        sender_first_name: userProfile.first_name,
        sender_intention: messageInstructionsAtom.intention,
        sender_last_name: userProfile.last_name,
        sender_preferences: messageInstructionsAtom.preferences,
        sender_title: messageInstructionsAtom.title,
        sender_value_proposition: messageInstructionsAtom.valueProposition,
        recipient_summary: enrichmentPerson.cleaned_summary,
        recipient_employer_summary: enrichmentCompany.cleaned_summary,
        message_body_raw: generatedMessage.bodyRaw,
        message_body: generatedMessage.body,
        message_header: generatedMessage.header,
        message_signature: messageInstructionsAtom.messageSignature,
        label: label
      }
    })
  }

  //Listeners
  useEffect(() => {
    if (!!enrichmentPerson?.employer_url) {
      setEnrichmentCompanyUrl(enrichmentPerson?.employer_url)
    }
  }, [enrichmentPerson, setEnrichmentCompanyUrl])

  useEffect(() => {
    if (
      userProfileFetchStatus === 'success' &&
      (!userProfile?.first_name || !userProfile?.last_name)
    ) {
      setShowProfileInfoModal(true)
    }
  }, [userProfile, userProfileFetchStatus])

  useEffect(() => {
    if (userCreditsFetchStatus === 'success' && userCredits?.available_credit === 0) {
      setShowCreditInfoModal(true)
    }
  }, [userCredits, userCreditsFetchStatus])

  return (
    <>
      {showCreditInfoModal && (
        <UserCreditWarningModal
          show={showCreditInfoModal}
          onHide={() => setShowCreditInfoModal(false)}
        />
      )}
      {showProfileInfoModal && (
        <UserProfileWarningInfoModal
          show={showProfileInfoModal}
          onHide={() => setShowProfileInfoModal(false)}
        />
      )}
      <SidebarTemplate>
        <Paper
          className='d-flex flex-column align-items-center background--p-light'
          variant='outlined'
          style={{padding: '1rem', borderRadius: '1rem', margin: '2rem'}}
        >
          <MessageInstructionsInput
            allInputInformationIsCorrect={allInputInformationIsCorrect}
            companyNameError={companyNameError}
            enrichmentCompany={enrichmentCompany}
            enrichmentCompanyFetchStatus={enrichmentCompanyFetchStatus}
            enrichmentCompanyStatus={enrichmentCompanyStatus}
            enrichmentCompanyUrl={enrichmentCompanyUrl}
            enrichmentCompanyUrlIsDebouncing={enrichmentCompanyUrlIsDebouncing}
            enrichmentPerson={enrichmentPerson}
            enrichmentPersonFetchStatus={enrichmentPersonFetchStatus}
            enrichmentPersonStatus={enrichmentPersonStatus}
            enrichmentPersonUrl={enrichmentPersonUrl}
            enrichmentPersonUrlIsDebouncing={enrichmentPersonUrlIsDebouncing}
            enrichmentPersonPhoneNumbers={enrichmentPersonPhoneNumbers}
            enrichmentPersonPhoneNumbersStatus={enrichmentPersonPhoneNumbersStatus}
            enrichmentPersonPhoneNumbersFetchStatus={
              enrichmentPersonPhoneNumbersFetchStatus
            }
            firstNameError={firstNameError}
            handleLoadMessageInstructions={handleLoadMessageInstructions}
            handlePatchMessageInstructions={handlePatchMessageInstructions}
            handlePostMessageInstructions={handlePostMessageInstructions}
            handleGetPhoneNumbers={enrichmentPersonPhoneNumbersRefetch}
            inputMessagesAreCorrect={inputMessagesAreCorrect}
            inputMessages={inputMessages}
            inputValidator={validateField}
            intentionError={intentionError}
            lastNameError={lastNameError}
            messageInformationInputIsCorrect={messageInformationInputIsCorrect}
            messageInstructions={messageInstructions}
            messageInstructionsAtom={messageInstructionsAtom}
            messageInstructionsFetchStatus={messageInstructionsFetchStatus}
            messages={messages}
            messagesFetchStatus={messagesFetchStatus}
            patchMessageInstructionsStatus={patchMessageInstructionsStatus}
            postMessageInstructionsStatus={postMessageInstructionsStatus}
            preferencesInformationInputIsCorrect={preferencesInformationInputIsCorrect}
            profileInformationInputIsCorrect={profileInformationInputIsCorrect}
            setEnrichmentCompanyUrl={setEnrichmentCompanyUrl}
            setEnrichmentPersonUrl={setEnrichmentPersonUrl}
            setInputMessages={setInputMessages}
            setMessageInstructionsKey={setMessageInstructionsKey}
            supportedLanguages={supportedLanguages}
            supportedLanguagesFetchStatus={supportedLanguagesFetchStatus}
            titleError={titleError}
            userProfile={userProfile}
            userProfileFetchStatus={userProfileFetchStatus}
            valuePropositionError={valuePropositionError}
          />
        </Paper>

        <Collapse
          in={allInputInformationIsCorrect}
          mountOnEnter
          unmountOnExit
        >
          <div>
            <Paper
              className='d-flex flex-column align-items-center background--p-light'
              variant='outlined'
              style={{
                padding: '1rem',
                borderRadius: '1rem',
                margin: '0rem 2rem 2rem 2rem'
              }}
            >
              <MessageInstructionsOutput
                generatedMessage={generatedMessage}
                userProfile={userProfile}
                postMessageIsEnabled={
                  !userAccessTokenIsError &&
                  !!userAccessToken &&
                  userAccessToken?.success
                }
                handlePostEmailSend={handlePostEmailSend}
                handlePostMessage={handlePostMessage}
                handlePostMessageGenerate={handlePostMessageGenerate}
                messageInstructionsAtom={messageInstructionsAtom}
                postCampaignEmailsSendStatus={postCampaignEmailsSendStatus}
                postMessageGenerateStatus={postMessageGenerateStatus}
                postMessageStatus={postMessageStatus}
                enrichmentPerson={enrichmentPerson}
              />
            </Paper>
          </div>
        </Collapse>
      </SidebarTemplate>
    </>
  )
}
