//ATOMS
import {useState, useRef} from 'react'
import {Paper} from '@mui/material'
import {
  Dropdown,
  Container,
  Row,
  Col,
  Form,
  FloatingLabel,
  OverlayTrigger,
  InputGroup,
  Tooltip
} from 'react-bootstrap'
import {ReactComponent as LinkedinIcon} from '../atoms/assets/Linkedin.svg'
import {Spinner} from '../atoms/Spinner.js'
import {CustomButton} from '../atoms/CustomButton.js'
import {successToast} from '../atoms/Toasts.js'

//MOLECULES
import {DynamicInputGroup} from '../molecules/DynamicInputGroup.js'

//ORGANISMS
import {
  SaveResearchInstructionsDialog,
  LoadResearchInstructionsDialog,
  SaveResearchResultsDialog
} from '../organisms/ResearchModals.js'

//TEMPLATES
import {SidebarTemplate} from '../templates/SidebarTemplate.js'

//CLIENT STATES
import {
  useResearchInstructionsAtom,
  useResearchResultsAtom,
  useEnrichmentCompanyUrlAtom
} from '../../hooks/client/useAgentClientState.js'

//SERVER STATES
import {useGetEnrichmentCompany} from '../../hooks/server/useAudiencesServerState.js'
import * as AgentSS from '../../hooks/server/useAgentServerState.js'

//UTILS
import {
  useAutosizeTextArea,
  useDebounce,
  isValidUrl,
  isTruthy
} from '../../utils/hooks.js'

export const ResearchBuilder = () => {
  //CLIENT GLOBAL STATES
  const {researchResultsAtom, setResearchResultsAtomKey} = useResearchResultsAtom()
  const {
    researchInstructionsAtom,
    setResearchInstructionsAtom,
    setResearchInstructionsAtomKey,
    researchInstructionsFromResponse
  } = useResearchInstructionsAtom()
  const [enrichmentCompanyUrl, setEnrichmentCompanyUrl] = useEnrichmentCompanyUrlAtom()

  //CLIENT LOCAL STATES
  const [openSaveInstructionsDialog, setOpenSaveInstructionsDialog] = useState(false)
  const [openLoadInstructionsDialog, setOpenLoadInstructionsDialog] = useState(false)
  const [openSaveResultsDialog, setOpenSaveResultsDialog] = useState(false)
  const [debouncedEnrichmentCompanyUrl, enrichmentCompanyUrlIsDebouncing] = useDebounce(
    enrichmentCompanyUrl,
    1000
  )
  const intentionRef = useRef(null)
  useAutosizeTextArea(intentionRef, researchInstructionsAtom?.intention)

  //QUERY STATES
  const {
    data: enrichmentCompany,
    status: enrichmentCompanyStatus,
    fetchStatus: enrichmentCompanyFetchStatus
  } = useGetEnrichmentCompany({
    enabled: isValidUrl(debouncedEnrichmentCompanyUrl),
    params: {url: debouncedEnrichmentCompanyUrl}
  })
  const {data: researchInstructions, fetchStatus: researchInstructionsFetchStatus} =
    AgentSS.useGetResearchInstructions()

  //MUTATION STATES
  const {
    mutate: postResearchResultsGenerate,
    status: postResearchResultsGenerateStatus
  } = AgentSS.usePostResearchResultsGenerate()

  const {mutate: postResearchResults, status: postResearchResultsStatus} =
    AgentSS.usePostResearchResults()

  const {mutate: postResearchInstructions, status: postResearchInstructionsStatus} =
    AgentSS.usePostResearchInstructions()

  const {mutate: patchResearchInstructions, status: patchResearchInstructionsStatus} =
    AgentSS.usePatchResearchInstructions()

  //VARIABLES
  const companyUrlIsLoading =
    enrichmentCompanyUrlIsDebouncing || enrichmentCompanyFetchStatus === 'fetching'

  //HANDLERS
  const handlePostResearchResultsGenerate = () => {
    const payload = {
      intention: researchInstructionsAtom?.intention,
      questions: researchInstructionsAtom?.questions,
      preferences: researchInstructionsAtom?.preferences,
      input: enrichmentCompany?.cleaned_summary
    }

    postResearchResultsGenerate(
      {payload},
      {
        onSuccess: (response) => {
          setResearchResultsAtomKey('result', response.result)
        }
      }
    )
  }

  const handleLoadResearchInstructions = (researchInstructionsId) => {
    const _researchInstructions = researchInstructions.find(
      (r) => r?.research_instructions_id === researchInstructionsId
    )
    setResearchInstructionsAtom(researchInstructionsFromResponse(_researchInstructions))
    successToast(`Research instructions "${_researchInstructions.label}" loaded!`)
  }

  const handlePatchResearchInstructions = () => {
    patchResearchInstructions({
      params: {
        research_instructions_id: researchInstructionsAtom.researchInstructionsId
      },
      payload: {
        intention: researchInstructionsAtom?.intention,
        questions: researchInstructionsAtom?.questions,
        preferences: researchInstructionsAtom?.preferences,
        label: researchInstructionsAtom?.label
      }
    })
  }

  const handlePostResearchResults = () => {
    const payload = {
      research_instructions_id: researchInstructionsAtom.researchInstructionsId,
      label: researchResultsAtom?.label,
      result: researchResultsAtom?.result,
      company_linkedin_url: debouncedEnrichmentCompanyUrl
    }
    postResearchResults(
      {
        payload
      },
      {
        onSuccess: () => {
          setResearchResultsAtomKey('label', null)
        }
      }
    )
  }

  const handlePostResearchInstructions = () => {
    const payload = {
      intention: researchInstructionsAtom?.intention,
      questions: researchInstructionsAtom?.questions,
      preferences: researchInstructionsAtom?.preferences,
      label: researchInstructionsAtom?.label
    }
    postResearchInstructions(
      {
        payload
      },
      {
        onSuccess: () => {
          setResearchInstructionsAtomKey('label', null)
        }
      }
    )
  }

  //LISTENERS

  return (
    <>
      <SaveResearchResultsDialog
        show={openSaveResultsDialog}
        onHide={() => setOpenSaveResultsDialog(false)}
        setResearchResultsAtomKey={setResearchResultsAtomKey}
        researchResultsAtom={researchResultsAtom}
        handlePostResearchResults={() => handlePostResearchResults()}
        postResearchResultsStatus={postResearchResultsStatus}
      />

      <LoadResearchInstructionsDialog
        handleLoadResearchInstructions={handleLoadResearchInstructions}
        researchInstructions={researchInstructions}
        researchInstructionsFetchStatus={researchInstructionsFetchStatus}
        onHide={() => setOpenLoadInstructionsDialog(false)}
        open={openLoadInstructionsDialog}
      />
      <SaveResearchInstructionsDialog
        allInputIsCorrect={true}
        onHide={() => setOpenSaveInstructionsDialog(false)}
        open={openSaveInstructionsDialog}
        patchResearchInstructions={() => handlePatchResearchInstructions()}
        patchResearchInstructionsStatus={patchResearchInstructionsStatus}
        postResearchInstructions={() => handlePostResearchInstructions()}
        postResearchInstructionsStatus={postResearchInstructionsStatus}
        researchInstructions={researchInstructions}
        researchInstructionsAtom={researchInstructionsAtom}
        researchInstructionsFetchStatus={researchInstructionsFetchStatus}
        setResearchInstructionsAtomKey={setResearchInstructionsAtomKey}
      />
      <SidebarTemplate>
        <Paper
          className='background--p-light'
          variant='outlined'
          style={{padding: '1rem', borderRadius: '1rem', margin: '2rem'}}
        >
          <div className='d-flex align-items-center justify-content-between'>
            <h5 className='d-text__font--heading color--p-dark'>Research</h5>
            <Dropdown drop='start'>
              <Dropdown.Toggle
                variant='none'
                bsPrefix='dropdownToggle'
                style={{border: 'none', padding: 0}}
                children={
                  <span
                    className='material-symbols-outlined transition-fast clickable t-scale--small t-color--accent'
                    style={{fontSize: '2rem', color: 'black'}}
                  >
                    {'more_horiz'}
                  </span>
                }
              />
              <Dropdown.Menu>
                <Dropdown.ItemText>Instructions</Dropdown.ItemText>
                <Dropdown.Item
                  onClick={() =>
                    setOpenLoadInstructionsDialog(!openLoadInstructionsDialog)
                  }
                >
                  Load
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    setOpenSaveInstructionsDialog(!openSaveInstructionsDialog)
                  }
                >
                  Save
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.ItemText>Results</Dropdown.ItemText>
                <Dropdown.Item
                  onClick={() => setOpenSaveResultsDialog(!openSaveResultsDialog)}
                >
                  Save
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <hr style={{marginTop: 0}} />

          <Container fluid>
            <Row>
              <Col
                xs={6}
                xxl={5}
              >
                <Form className='d-form'>
                  <Form.Group as={Row}>
                    <InputGroup as={Col}>
                      <InputGroup.Text
                        className={
                          !isTruthy(enrichmentCompanyUrl) ? 'invalidInput' : ''
                        }
                      >
                        <LinkedinIcon
                          style={{
                            height: 'inherit',
                            width: '2rem',
                            fill: '#0a66c2'
                          }}
                        />
                      </InputGroup.Text>

                      <FloatingLabel label={'Company Profile URL'}>
                        <Form.Control
                          className={
                            !isTruthy(enrichmentCompanyUrl) ? 'invalidInput' : ''
                          }
                          type='text'
                          value={enrichmentCompanyUrl || ''}
                          onChange={(e) => setEnrichmentCompanyUrl(e.target.value)}
                          name='company-profile'
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault()
                              e.target.blur()
                            }
                          }}
                          placeholder='https://www.linkedin.com/in/pitchr/'
                        />
                      </FloatingLabel>

                      {companyUrlIsLoading && (
                        <InputGroup.Text style={{borderLeft: 'none'}}>
                          <Spinner
                            size='sm'
                            animation='grow'
                          />
                        </InputGroup.Text>
                      )}
                      {enrichmentCompanyStatus === 'success' &&
                        !companyUrlIsLoading && (
                          <InputGroup.Text style={{borderLeft: 'none'}}>
                            <span className='material-symbols-outlined color--success'>
                              {'check'}
                            </span>
                          </InputGroup.Text>
                        )}
                      {enrichmentCompanyStatus === 'error' && !companyUrlIsLoading && (
                        <InputGroup.Text style={{borderLeft: 'none'}}>
                          <span
                            className='material-symbols-outlined'
                            style={{color: 'red'}}
                          >
                            {'close'}
                          </span>
                        </InputGroup.Text>
                      )}
                    </InputGroup>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <InputGroup as={Col}>
                      <FloatingLabel label='Your Intention'>
                        <Form.Control
                          as='textarea'
                          ref={intentionRef}
                          rows={1}
                          name='intention'
                          placeholder=''
                          value={researchInstructionsAtom?.intention || ''}
                          onChange={(e) =>
                            setResearchInstructionsAtomKey('intention', e.target.value)
                          }
                          style={{borderRight: 'none'}}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault()
                              e.target.blur()
                            }
                          }}
                        />
                      </FloatingLabel>
                      <InputGroup.Text style={{borderLeft: 'none'}}>
                        <OverlayTrigger
                          placement='bottom'
                          overlay={
                            <Tooltip id='intention-help-tooltip'>
                              What would you like to achieve with the message?
                              <br /> Example: Invite the prospect to a product
                              demonstration.
                            </Tooltip>
                          }
                        >
                          <span className='material-symbols-outlined color--s-dark'>
                            {'help'}
                          </span>
                        </OverlayTrigger>
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>

                  <h6 style={{textAlign: 'center', paddingBottom: 0, marginBottom: 0}}>
                    Your Research Questions
                  </h6>
                  <InputGroup
                    as={Row}
                    style={{
                      maxHeight: '15rem',
                      overflowY: 'auto'
                    }}
                  >
                    <DynamicInputGroup
                      onChange={(p) => setResearchInstructionsAtomKey('questions', p)}
                      values={researchInstructionsAtom?.questions}
                      inputValidator={() => {}}
                    />
                  </InputGroup>

                  <h6 style={{textAlign: 'center', paddingBottom: 0, marginBottom: 0}}>
                    Your Research Results Preferences
                  </h6>
                  <InputGroup
                    as={Row}
                    style={{
                      maxHeight: '15rem',
                      overflowY: 'auto'
                    }}
                  >
                    <DynamicInputGroup
                      onChange={(p) => setResearchInstructionsAtomKey('preferences', p)}
                      values={researchInstructionsAtom?.preferences}
                      inputValidator={() => {}}
                    />
                  </InputGroup>
                </Form>
              </Col>

              <Col>
                <div
                  className='d-flex flex-column justify-content-start align-items-center'
                  style={{padding: '0 1rem 1rem 1rem'}}
                >
                  <CustomButton
                    onClick={() => handlePostResearchResultsGenerate()}
                    iconName={'search'}
                    label={'Start Research'}
                    isLoading={postResearchResultsGenerateStatus === 'pending'}
                  />

                  <p style={{whiteSpace: 'pre-wrap', padding: '1rem'}}>
                    {researchResultsAtom?.result}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </Paper>
      </SidebarTemplate>
    </>
  )
}
