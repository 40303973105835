import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import Lottie from 'react-lottie-player'

import {Container, Stack, Col, Row, Fade, Image} from 'react-bootstrap'
import {Stepper, Step, StepLabel, Paper, StepContent} from '@mui/material'
import {Masonry} from '@mui/lab'

import {Button} from '../../atoms/Button.js'
import {Spinner} from '../../atoms/Spinner.js'

import {RotatingHeaderWrapper} from '../../molecules/RotatingHeaderWrapper.js'

import {InfiniteLooper} from '../../organisms/InfiniteLooper.js'

import {InfoPageTemplate} from '../../templates/InfoPageTemplate.js'

import {useBreakpoints, generateStaticContentUrl} from '../../../utils/helpers.js'
import {
  useGetClientConfig,
  useGetStaticContent
} from '../../../hooks/server/useUtilitiesServerState.js'

const WorkflowSegment = ({isLgOrMore}) => {
  //CLIENT STATE
  const [fadeIn, setFadeIn] = useState(true)
  const [activeStep, setActiveStep] = useState(0)
  const [autoAdvance, setAutoAdvance] = useState(true)
  const [animationFile, setAnimationFile] = useState('/animations/GetStarted.json')

  //SERVER STATE
  const {
    data: clientConfig,
    status: clientConfigStatus,
    fetchStatus: clientConfigFetchStatus
  } = useGetClientConfig()

  const {data: animation, fetchStatus: animationFetchStatus} = useGetStaticContent({
    server: clientConfig?.staticContentServer,
    bucket: clientConfig?.staticContentBucket,
    file: animationFile,
    enabled: clientConfigStatus === 'success'
  })

  // VARIABLES
  const steps = [
    {
      header: 'Get Connected',
      footer: 'Sign up and connect your email account.',
      icon: 'start',
      animationFile: '/animations/GetStarted.json'
    },
    {
      header: 'Target Audience',
      footer: 'Identify and save the right audience.',
      icon: 'groups',
      animationFile: '/animations/AudienceBuilder.json'
    },
    {
      header: 'Tailor Message',
      footer: 'Guide our AI to create messages you want to share.',
      icon: 'chat',
      animationFile: '/animations/MessageBuilder.json'
    },
    {
      header: 'Setup Campaign',
      footer: 'Instruct AI how you want to reach your audience.',
      icon: 'campaign',
      animationFile: '/animations/CampaignBuilder.json'
    },
    {
      header: 'Track Results',
      footer: `Monitor your campaign's performance and metrics.`,
      icon: 'dashboard',
      animationFile: '/animations/CampaignInsights.json'
    }
  ]

  // HANDLERS
  const handleStepChange = (stepIndex) => {
    if (stepIndex !== activeStep) {
      setFadeIn(false)
      setTimeout(() => {
        setActiveStep(stepIndex)
        const step = steps[stepIndex]
        setAnimationFile(step.animationFile)
        setFadeIn(true)
      }, 150)
    }
  }

  return (
    <>
      <RotatingHeaderWrapper
        firstPart={'Scaling up outreach for your '}
        rotatingWords={[
          'Start-Up',
          'Sales Team',
          'Marketing Department',
          'Recruitment Business'
        ]}
        style={{
          marginBottom: '5rem',
          maxWidth: '32rem',
          textWrap: 'wrap',
          alignSelf: 'center'
        }}
        className='d-text__font--subheading d-text__placement--center color--p-dark '
      />
      <Stack direction={isLgOrMore ? 'horizontal' : 'vertical'}>
        {isLgOrMore ? (
          <Stepper
            activeStep={activeStep}
            orientation={isLgOrMore ? 'vertical' : 'horizontal'}
            style={{
              alignSelf: isLgOrMore ? 'flex-start' : 'center',
              paddingRight: '2rem',
              width: '20rem'
            }}
          >
            {steps.map((step, index) => (
              <Step
                key={index}
                onClick={() => {
                  setAutoAdvance(false)
                  handleStepChange(index)
                }}
              >
                <StepLabel
                  StepIconComponent={() => (
                    <span
                      className={
                        activeStep === index
                          ? 'material-symbols-outlined color--accent'
                          : 'material-symbols-outlined color--s-dark clickable transition-medium t-color--accent'
                      }
                      style={{
                        fontSize: '2.074rem',
                        lineHeight: '2.074rem',
                        height: '2.074rem',
                        verticalAlign: 'middle'
                      }}
                    >
                      {step.icon}
                    </span>
                  )}
                >
                  <h5
                    className={
                      activeStep === index
                        ? 'd-text__font--subheading d-text__placement--center color--accent clickable'
                        : 'd-text__font--subheading d-text__placement--center color--p-dark clickable transition-medium t-color--accent'
                    }
                    style={{textWrap: 'nowrap', textAlign: 'start'}}
                  >
                    {step.header}
                  </h5>
                </StepLabel>
                <StepContent>
                  <p className='d-text__font--body color--s-dark'>{step.footer}</p>

                  <Button
                    variant='link'
                    style={{textDecoration: 'none'}}
                    className='color--accent clickable d-flex'
                    onClick={() => {
                      index < steps.length - 1
                        ? handleStepChange(index + 1)
                        : handleStepChange(0)
                    }}
                  >
                    {index < steps.length - 1 ? 'Next' : 'Start'}
                    <span className='material-symbols-outlined'>
                      {'arrow_right_alt'}
                    </span>
                  </Button>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        ) : (
          <div
            className='d-flex justify-content-center flex-wrap'
            style={{marginBottom: '2rem'}}
          >
            {steps.map((step, index) => (
              <Button
                key={index}
                children={step.header}
                variant={activeStep === index ? 'primary' : 'outline-primary'}
                onClick={() => {
                  setAutoAdvance(false)
                  handleStepChange(index)
                }}
                style={{margin: '0.5rem'}}
              />
            ))}
          </div>
        )}
        <Paper
          className='background--s-light'
          style={{padding: '1rem', width: '100%'}}
          elevation={1}
        >
          <Fade in={fadeIn}>
            <div>
              {!isLgOrMore && (
                <p
                  className='d-text__font--body color--s-dark d-flex align-items-center'
                  style={{padding: '1rem'}}
                >
                  <span
                    className='material-symbols-outlined color--s-dark'
                    style={{marginRight: '1rem'}}
                  >
                    {steps[activeStep].icon}
                  </span>
                  {steps[activeStep].footer}
                </p>
              )}
              {animationFetchStatus === 'fetching' ||
              clientConfigFetchStatus === 'fetching' ? (
                <div
                  className='d-flex align-items-center justify-content-center'
                  style={{width: '100%', aspectRatio: '16 / 10'}}
                >
                  <Spinner />
                </div>
              ) : (
                <Lottie
                  key={`${steps[activeStep].icon}-animation`}
                  animationData={animation}
                  loop={!autoAdvance}
                  renderer='svg'
                  play
                  style={{width: 'auto'}}
                  onComplete={() =>
                    autoAdvance && activeStep < steps.length - 1
                      ? handleStepChange(activeStep + 1)
                      : handleStepChange(0)
                  }
                />
              )}
            </div>
          </Fade>
        </Paper>
      </Stack>
    </>
  )
}

const FeatureSegment = ({isMdOrMore}) => {
  const navigate = useNavigate()

  //SERVER STATE
  const {data: clientConfig, fetchStatus: clientConfigFetchStatus} =
    useGetClientConfig()

  const features = [
    {
      header: 'Up-to-date B2B Data',
      body: 'Pitchr is essential for businesses looking to optimize their sales strategies and achieve better results. In a constantly evolving market, accurate and current information allows companies to identify the right leads, personalize outreach, and improve conversion rates. By leveraging the most recent data, businesses can stay competitive, make informed decisions, and efficiently target prospects who are more likely to convert.',
      imageSrc: generateStaticContentUrl({
        server: clientConfig?.staticContentServer,
        bucket: clientConfig?.staticContentBucket,
        file: '/images/DiscoveryB2B.png'
      }),
      icon: 'apartment',
      onClick: () => {
        navigate('/data#data')
      }
    },
    {
      header: 'Verified Email Addresses',
      body: 'We help ensure your messages reach the right audience, reducing wasted efforts and improving engagement. With our service, you can focus on your core activities while we handle the details, giving you the confidence that your communications are on target.',
      imageSrc: generateStaticContentUrl({
        server: clientConfig?.staticContentServer,
        bucket: clientConfig?.staticContentBucket,
        file: '/images/DiscoveryEmailVerified.png'
      }),
      icon: 'mark_email_read',
      onClick: () => {
        navigate('/data#data')
      }
    },
    {
      header: 'Branding',
      body: 'Strengthen your brand identity by incorporating custom email signatures into your communications. Every email you send is an opportunity to reinforce your brand, build recognition, and leave a lasting impression. A well-crafted signature not only makes your messages look professional but also consistently highlights your brand’s values and personality. In a crowded inbox, these small but impactful details help your brand stand out, making every interaction more memorable and enhancing your overall brand presence.',
      imageSrc: generateStaticContentUrl({
        server: clientConfig?.staticContentServer,
        bucket: clientConfig?.staticContentBucket,
        file: '/images/DiscoveryEmailBranding.png'
      }),
      icon: 'brand_awareness',
      onClick: () => {
        navigate('/data#data')
      }
    },
    {
      header: 'AI-Assisted Prospecting',
      body: `With our analytics feature, you can easily keep track of how your campaigns are performing. Get detailed insights that help you understand what's working and where you can improve. The ability to export these insights gives you the flexibility to dive deeper into the data whenever you need. Instead of guessing, you can make informed decisions that drive better results, helping you fine-tune your approach and achieve your goals more effectively.`,
      imageSrc: generateStaticContentUrl({
        server: clientConfig?.staticContentServer,
        bucket: clientConfig?.staticContentBucket,
        file: '/images/DiscoveryAiSorting.png'
      }),
      icon: 'monitoring',
      onClick: () => {
        navigate('/data#data')
      }
    },
    {
      header: 'Analytics',
      body: `Pitchr is a game-changer for sales teams, making it easier to connect with the right employees at your target companies. Instead of spending hours searching for leads, let AI do the heavy lifting by identifying key decision-makers quickly and accurately. This means you can focus on what really matters - building relationships and closing deals, all while AI takes care of finding the best prospects. It's a smarter, more efficient way to work that saves time and boosts your chances of success in a competitive market.`,
      imageSrc: generateStaticContentUrl({
        server: clientConfig?.staticContentServer,
        bucket: clientConfig?.staticContentBucket,
        file: '/images/DiscoveryAnalytics.png'
      }),
      icon: 'neurology',
      onClick: () => {
        navigate('/data#data')
      }
    },
    {
      header: 'Email Warmup',
      body: `Email warm-up services gradually increase the sending volume of a new or inactive email account, helping to establish a positive sender reputation with email providers. By engaging with real inboxes, these services reduce the chances of emails being marked as spam, improving overall deliverability and inbox placement. This ensures that cold outreach, marketing campaigns, and business communications reach their intended recipients, leading to higher engagement and response rates.`,
      imageSrc: generateStaticContentUrl({
        server: clientConfig?.staticContentServer,
        bucket: clientConfig?.staticContentBucket,
        file: '/images/DiscoveryEmailWarmup.png'
      }),
      icon: 'readiness_score',
      onClick: () => {
        navigate('/data#data')
      }
    }
  ]

  return (
    <>
      <RotatingHeaderWrapper
        firstPart={'A solution built for '}
        rotatingWords={[
          'Sales Representatives',
          'Recruiters',
          'Business Developers',
          'Account Executives'
        ]}
        style={{
          marginBottom: '5rem',
          alignSelf: 'flex-start',
          paddingLeft: '1.5rem',
          maxWidth: '28rem',
          textWrap: 'wrap'
        }}
        className='d-text__font--subheading color--p-light'
      />
      <Masonry
        columns={isMdOrMore ? 2 : 1}
        spacing={isMdOrMore ? 6 : 4}
      >
        {features.map((feature, index) => (
          <Paper
            className='background--s-light clickable transition-medium t-scale--large t-background--p-light t-border-color--accent'
            style={{padding: '0.5rem', border: '1px solid var(--primary-light)'}}
            key={index}
            elevation={8}
            onClick={feature.onClick}
          >
            <div
              className='d-flex justify-content-between align-items-center'
              style={{padding: '1.6rem', paddingBottom: 0}}
            >
              <h3 className='d-text__font--subheading dark'>{feature.header}</h3>
              <span
                className='material-symbols-outlined color--s-dark'
                style={{
                  fontSize: '2.074rem',
                  lineHeight: '2.074rem',
                  height: '2.074rem',
                  verticalAlign: 'middle'
                }}
              >
                {feature.icon}
              </span>
            </div>
            <p
              className='d-text__font--body color--p-dark'
              style={{
                padding: '1.5rem',
                paddingTop: 0,
                paddingBottom: '0.5rem',
                textWrap: 'pretty'
              }}
            >
              {feature.body}
            </p>
            <Paper
              className='background--p-light d-flex align-items-center justify-content-center'
              style={{padding: '1rem', borderRadius: '1rem'}}
              elevation={0}
            >
              {clientConfigFetchStatus === 'fetching' ? (
                <Spinner />
              ) : (
                <Image
                  src={feature.imageSrc}
                  fluid
                  rounded
                  style={{
                    borderRadius: '10px',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.3)'
                  }}
                />
              )}
            </Paper>
          </Paper>
        ))}
      </Masonry>
    </>
  )
}

const ScheduleDemoSegment = ({isXs}) => {
  const navigate = useNavigate()
  return (
    <Paper
      className='background--p-dark transition-slow t-border-color--accent'
      style={{
        padding: '5rem',
        width: '-webkit-fill-available',
        border: '1px solid var(--primary-dark)'
      }}
      variant={'outlined'}
      elevation={0}
    >
      <Row>
        <Col>
          <RotatingHeaderWrapper
            firstPart={'Ready to take your sales\n'}
            rotatingWords={[
              'up a notch?',
              'to the next level?',
              'where your target is?'
            ]}
            style={{textAlign: 'start', textWrap: 'balance', whiteSpace: 'pre-wrap'}}
            className='d-text__font--subheading color--p-light'
            tag='h4'
          />
          <p
            className='d-text__font--body color--s-light'
            style={{
              textAlign: 'start',
              textWrap: 'balance'
            }}
          >
            Learn how Pitchr’s service and features can help you start, optimize or grow
            your business.
          </p>
          <div className='d-flex align-items-center justify-content-start'>
            <h7
              style={{marginTop: '0.5rem', textAlign: 'start'}}
              className='d-text__font--body-bold color--s-light'
            >
              ✓ GDPR Compliant <br />✓ CASA Tier 2 Certified
            </h7>
          </div>
        </Col>
        <Col
          xs={13}
          sm={'auto'}
          className='d-flex justify-content-center align-items-center'
        >
          <Button
            style={{marginTop: isXs ? '2rem' : 0}}
            children={
              <>
                {'Schedule A Demo Now'}
                <span
                  className='material-symbols-outlined'
                  style={{marginLeft: '0.5rem'}}
                >
                  {'calendar_month'}
                </span>
              </>
            }
            onClick={() => {
              navigate('/demo#schedule')
            }}
            variant='outline-secondary'
          />
        </Col>
      </Row>
    </Paper>
  )
}

const StatisticsSegment = () => {
  const accent1 = '#F7A00D'
  const accent2 = '#DB351A'
  const accent3 = '#E4653D'
  const cards = [
    {
      type: 1,
      heading: 'Save Time',
      body: `Save time and close more deals with Pitchr, cutting prospecting and email creation time.`,
      accent: accent1,
      icon: 'avg_pace'
    },
    {
      type: 2,
      heading: 'Conversations Started',
      body: `100+`,
      accent: accent1,
      icon: 'mark_unread_chat_alt'
    },
    {
      type: 1,
      heading: 'Cut Costs',
      body: `Slash costs by saving over $90,000 annually per SDR with reduced time spent on lead generation and personalized outreach.`,
      accent: accent2,
      icon: 'trending_up'
    },
    {
      type: 2,
      heading: 'Profiles Scanned',
      body: `9000+`,
      accent: accent2,
      icon: 'person_search'
    },
    {
      type: 1,
      heading: 'AI Precision',
      body: `AI-powered precision helps avoid the spam folder with personalized, resonant content for each recipient.`,
      accent: accent3,
      icon: 'ads_click'
    },
    {
      type: 2,
      heading: 'Prospecting Hours Saved',
      body: `1000+`,
      accent: accent3,
      icon: 'domain'
    },
    {
      type: 1,
      heading: 'Boost Efficiency',
      body: `Supercharge your SDRs by cutting their outbound efforts with over 20 hours per week with Pitchr.`,
      accent: accent1,
      icon: 'handshake'
    },
    {
      type: 2,
      heading: 'Campaigns Started',
      body: `50+`,
      accent: accent1,
      icon: 'handshake'
    },
    {
      type: 1,
      heading: 'Max ROI',
      body: `Maximize ROI by investing in Pitchr, reducing costs, and boosting your conversion rates.`,
      accent: accent2,
      icon: 'credit_card_heart'
    },
    {
      type: 2,
      heading: 'Messages Sent',
      body: `2000+`,
      accent: accent2,
      icon: 'mark_email_read'
    }
  ]

  return (
    <InfiniteLooper
      speed={120}
      direction='left'
    >
      {cards.map((card, index) =>
        card.type === 1 ? (
          <Paper
            key={`${index}-card`}
            className='d-flex flex-column justify-content-start'
            style={{
              background: `rgba(${parseInt(card.accent.slice(1, 3), 16)}, ${parseInt(
                card.accent.slice(3, 5),
                16
              )}, ${parseInt(card.accent.slice(5, 7), 16)}, 0.15)`,

              padding: '2rem',
              maxWidth: '24rem',
              margin: '2rem',
              border: `2px solid ${card.accent}`,
              position: 'relative',
              overflow: 'hidden',
              borderRadius: '1rem'
            }}
            variant='outlined'
          >
            <span
              key={`${index}-icon`}
              className={'material-symbols-outlined'}
              style={{
                fontSize: '20rem',
                position: 'absolute',
                color: `var(--primary-dark)`,
                top: '70%',
                left: '70%',
                transform: 'translate(-50%, -50%)',
                pointerEvents: 'none',
                zIndex: 1
              }}
            >
              {card.icon}
            </span>
            <h4
              className='d-text__font--heading'
              style={{
                color: card.accent,
                fontWeight: 700,
                zIndex: 2
              }}
            >
              {card.heading}
            </h4>
            <h6
              className='d-text__font--heading'
              style={{color: card.accent, textWrap: 'pretty', zIndex: 2}}
            >
              {card.body}
            </h6>
          </Paper>
        ) : (
          <Paper
            key={`${index}-card`}
            className='background--s-dark d-flex flex-column justify-content-center align-items-center'
            style={{
              padding: '2rem',
              maxWidth: '24rem',
              margin: '2rem',
              border: `2px solid ${card.accent}`,
              position: 'relative',
              overflow: 'hidden',
              borderRadius: '1rem'
            }}
            variant='outlined'
          >
            <span
              key={`${index}-icon`}
              className={'material-symbols-outlined'}
              style={{
                fontSize: '20rem',
                opacity: 0.1,
                position: 'absolute',
                color: card.accent,
                top: '20%',
                left: '30%',
                transform: 'translate(-50%, -50%)',
                pointerEvents: 'none'
              }}
            >
              {card.icon}
            </span>
            <h4
              className='d-text__font--heading'
              style={{
                color: card.accent,
                fontWeight: 700,
                zIndex: 1
              }}
            >
              {card.body}
            </h4>
            <p
              className='d-text__font--heading'
              style={{color: card.accent, textWrap: 'pretty', zIndex: 1}}
            >
              {card.heading}
            </p>
          </Paper>
        )
      )}
    </InfiniteLooper>
  )
}

const TargetIndustrySegment = ({smOrLarger}) => {
  //SERVER STATE
  const {data: clientConfig, fetchStatus: clientConfigFetchStatus} =
    useGetClientConfig()

  const targetCompanies = [
    {
      header: 'Recruitment Agencies',
      body: 'Easily find and contact new companies that need your services. Focus more on quality hiring and delivering great results.',
      icon: 'person_search'
    },
    {
      header: 'B2B Organizations',
      body: 'Best for businesses that want to grow their sales efforts and improve lead generation.',
      icon: 'business_center'
    },
    {
      header: 'Startup Companies',
      body: 'Perfect for startups that need flexible and scalable solutions to succeed and stay competitive.',
      icon: 'trending_up'
    },
    {
      header: 'Enterprise Organizations',
      body: 'Good for large companies that want to explore new markets with low risk and high reward potential.',
      icon: 'domain'
    },
    {
      header: 'Marketing Agencies',
      body: 'Ideal for marketing agencies that need an easy-to-use tool for generating leads and reaching out to more clients with less effort.',
      icon: 'ad'
    }
  ]

  const targetUsers = [
    {
      header: 'Commercial Leaders',
      body: 'Improve your teams efficiency, have them spend time where it matters the most. Get insights on how your team is performing and make data-driven improvements.',
      icon: 'domain'
    },
    {
      header: 'Founders',
      body: 'Access and reach your Ideal Customers with a well crafted message without employing an entire sales team. Grow your business with ease, ensuring you have a steady outreach without losing valuable time.',
      icon: 'business_center'
    },
    {
      header: 'Sales Development Representatives',
      body: 'Automate time consuming tasks such as prospecting & email template writing and spend more time where it really matters.',
      icon: 'trending_up'
    },
    {
      header: 'Account Executives',
      body: 'Fuel your own pipeline with demos, wake up in the morning to new “inbounds” and put your efforts on where it matters - closing deals!',
      icon: 'ad'
    },
    {
      header: 'Recruiters',
      body: 'Efficiently identify qualified candidates for your job openings while simultaneously connecting you with companies in need of your services, providing a dual-purpose tool to support your growth and operational goals.',
      icon: 'person_search'
    }
  ]

  const recruiterImageSrc = generateStaticContentUrl({
    server: clientConfig?.staticContentServer,
    bucket: clientConfig?.staticContentBucket,
    file: '/images/DiscoveryRecruiter.png'
  })

  const founderImageSrc = generateStaticContentUrl({
    server: clientConfig?.staticContentServer,
    bucket: clientConfig?.staticContentBucket,
    file: '/images/DiscoveryFounder.png'
  })

  return (
    <>
      <Row
        className='d-flex justify-content-center align-items-center'
        style={{marginBottom: '10rem'}}
      >
        <Col
          className='d-flex flex-column justify-content-start'
          xs={10}
          md={8}
        >
          <RotatingHeaderWrapper
            firstPart={'What businesses should consider\n'}
            rotatingWords={['Pitchr?', 'our platform?']}
            style={{
              padding: '1.5rem',
              paddingBottom: '3rem',
              textAlign: smOrLarger ? 'start' : 'center',
              textWrap: 'nowrap',
              whiteSpace: 'pre-wrap'
            }}
            className='d-text__font--subheading color--p-dark'
            tag='h2'
          />
          <div
            className={
              smOrLarger
                ? 'd-flex flex-wrap justify-content-start align-items-start'
                : 'd-flex flex-wrap justify-content-center align-items-start'
            }
          >
            {targetCompanies.map((company, index) => (
              <div
                key={index}
                style={{maxWidth: '18rem', padding: '1.5rem'}}
              >
                <span
                  className={'material-symbols-outlined color--accent'}
                  style={{marginRight: '0.5rem', fontSize: '1.728rem'}}
                >
                  {company.icon}
                </span>
                <h4 className='d-text__font--subheading color--p-dark'>
                  {company.header}
                </h4>
                <p
                  className='d-text__font--body color--p-dark'
                  style={{textWrap: 'balanced'}}
                >
                  {company.body}
                </p>
              </div>
            ))}
          </div>
        </Col>

        {smOrLarger && (
          <Col
            md={4}
            xs={6}
            className='d-flex flex-column justify-content-center'
            style={{alignSelf: 'center'}}
          >
            <Paper
              className='background--s-light'
              style={{borderRadius: '1rem'}}
              elevation={1}
            >
              {clientConfigFetchStatus === 'fetching' ? (
                <Spinner />
              ) : (
                <Image
                  src={recruiterImageSrc}
                  fluid
                  rounded
                  style={{padding: '2rem', paddingBottom: 0}}
                />
              )}
            </Paper>
          </Col>
        )}
      </Row>

      <Row className='d-flex justify-content-center align-items-center'>
        {smOrLarger && (
          <Col
            md={4}
            xs={6}
            className='d-flex flex-column justify-content-center'
            style={{alignSelf: 'center'}}
          >
            <Paper
              className='background--s-light'
              style={{borderRadius: '1rem'}}
              elevation={1}
            >
              {clientConfigFetchStatus === 'fetching' ? (
                <Spinner />
              ) : (
                <Image
                  src={founderImageSrc}
                  fluid
                  rounded
                  style={{padding: '2rem', paddingBottom: 0}}
                />
              )}
            </Paper>
          </Col>
        )}

        <Col
          className='d-flex flex-column justify-content-end'
          style={{alignSelf: 'flex-end'}}
          xs={10}
          md={8}
        >
          <RotatingHeaderWrapper
            rotatingWords={['What roles', 'Who']}
            lastPart={'\nshould consider Pitchr?'}
            style={{
              padding: '1.5rem',
              paddingBottom: '3rem',
              textAlign: smOrLarger ? 'end' : 'center',
              textWrap: 'balance',
              whiteSpace: 'pre-wrap'
            }}
            className='d-text__font--subheading color--p-dark'
            tag='h2'
          />
          <div
            className={
              smOrLarger
                ? 'd-flex flex-wrap justify-content-end align-items-start'
                : 'd-flex flex-wrap justify-content-center align-items-start'
            }
          >
            {targetUsers.map((user, index) => (
              <div
                key={index}
                style={{maxWidth: '18rem', textWrap: 'balanced', padding: '1.5rem'}}
              >
                <span
                  className={'material-symbols-outlined color--accent'}
                  style={{marginRight: '0.5rem', fontSize: '1.728rem'}}
                >
                  {user.icon}
                </span>

                <h4 className='d-text__font--subheading color--p-dark'>
                  {user.header}
                </h4>
                <p
                  className='d-text__font--body color--p-dark'
                  style={{textWrap: 'balanced'}}
                >
                  {user.body}
                </p>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </>
  )
}

export const Discovery = () => {
  const {isXs, isMd, isLg, isXl, isXxl} = useBreakpoints()
  const contentColClass = 'd-flex flex-column justify-content-center align-items-center'
  const contentColBreakpoints = {
    xxl: 8,
    xl: 10,
    md: 11,
    xs: 12
  }

  return (
    <InfoPageTemplate>
      <Container
        id='workflow'
        fluid
        className='background--p-light d-flex justify-content-center align-items-center'
        style={{paddingTop: '13rem', paddingBottom: '13rem'}}
      >
        <Col
          {...contentColBreakpoints}
          className={contentColClass}
        >
          <WorkflowSegment isLgOrMore={isLg || isXl || isXxl} />
        </Col>
      </Container>

      <Container
        fluid
        className='background--p-dark d-flex justify-content-center align-items-center'
        style={{paddingTop: '13rem', paddingBottom: '13rem'}}
        id='features'
      >
        <Col
          {...contentColBreakpoints}
          className={contentColClass}
        >
          <FeatureSegment isMdOrMore={isMd || isLg || isXl || isXxl} />
        </Col>
      </Container>

      <Container
        fluid
        className='background--p-light d-flex justify-content-center align-items-center'
        style={{paddingTop: '6.5rem', paddingBottom: '13rem'}}
      >
        <Col
          {...contentColBreakpoints}
          className={contentColClass}
        >
          <h3 className='d-text__font--subheading color--p-dark'>
            Pitchr works with email service providers you know
          </h3>
          <div>
            <Image
              src={
                'https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/brand-icons/product/svg/outlook_48x1.svg'
              }
              alt={'Outlook'}
              style={{height: '4rem', margin: '2rem'}}
            />
            <Image
              src={
                'https://lh3.googleusercontent.com/0rpHlrX8IG77awQMuUZpQ0zGWT7HRYtpncsuRnFo6V3c8Lh2hPjXnEuhDDd-OsLz1vua4ld2rlUYFAaBYk-rZCODmi2eJlwUEVsZgg'
              }
              alt={'Gmail'}
              style={{height: '4rem', margin: '2rem'}}
            />
          </div>

          <h5
            className='d-text__font--body color--s-dark'
            style={{paddingBottom: '6.5rem'}}
          >
            {'✓ Application Verified by '}
            <strong>Google</strong> & <strong>Microsoft</strong>
          </h5>

          <ScheduleDemoSegment isXs={isXs} />
        </Col>
      </Container>

      <Container
        fluid
        className='background--p-dark'
        style={{paddingTop: '5rem', paddingBottom: '5rem'}}
      >
        <StatisticsSegment />
      </Container>

      <Container
        id='use-cases'
        fluid
        className='background--p-light d-flex justify-content-center align-items-center'
        style={{paddingTop: '13rem', paddingBottom: '13rem'}}
      >
        <Col
          {...contentColBreakpoints}
          className='d-flex flex-column'
        >
          <TargetIndustrySegment smOrLarger={isMd || isLg || isXl || isXxl} />
        </Col>
      </Container>
    </InfoPageTemplate>
  )
}
