import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query'
import * as agentApi from '../../services/agentApi.js'
import {errorToast, successToast} from '../../components/atoms/Toasts.js'

//LANGUAGES
export const useGetSupportedLanguages = () => {
  return useQuery({
    queryKey: ['messageSupportedLanguages'],
    queryFn: () => agentApi.getSupportedLanguages(),
    staleTime: 7 * 24 * 60 * 60 * 1000,
    cacheTime: 7 * 24 * 60 * 60 * 1000,
    meta: {
      errorMessage: 'Fetching Supported Languages Failed.\nPlease try again later.'
    }
  })
}

//LEADS
export const usePostLeadsRank = ({payload, enabled = true}) => {
  return useQuery({
    queryKey: ['leadsRank', payload],
    queryFn: () => agentApi.postLeadsRank({payload}),
    enabled: !!enabled,
    staleTime: 24 * 60 * 60 * 1000,
    cacheTime: 6 * 24 * 60 * 60 * 1000,
    meta: {
      errorMessage: 'AI Employee Ranking Failed.\nPlease try again later.'
    }
  })
}

//RESEARCHES
export const usePostResearchResultsGenerate = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({payload}) => agentApi.postResearchResultsGenerate({payload}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['userCredit']})
      successToast('Research Finished!')
    },
    onError: (e) => {
      let message = 'Generating Message Failed.\nPlease try again later.'
      if (e?.response?.status === 402) {
        message = e.message
      }
      errorToast(message)
    }
  })
}
export const useGetResearchInstructions = ({params} = {}) => {
  return useQuery({
    queryKey: ['researchInstructions', params],
    queryFn: () => agentApi.getResearchInstructions({params}),
    staleTime: 8 * 60 * 60 * 1000,
    cacheTime: 7 * 24 * 60 * 60 * 1000,
    meta: {
      errorMessage: 'Fetching Research Instructions Failed.\nPlease try again later.'
    }
  })
}
export const usePostResearchInstructions = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({payload}) => agentApi.postResearchInstructions({payload}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['researchInstructions']})
      successToast('Research Instructions Saved!')
    },
    onError: () => {
      errorToast('Saving Research Instructions Failed.\nPlease try again later.')
    }
  })
}
export const usePatchResearchInstructions = ({
  invalidateQuery = true,
  showToast = true
} = {}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({params, payload}) =>
      agentApi.patchResearchInstructions({params, payload}),
    onSuccess: () => {
      if (invalidateQuery)
        queryClient.invalidateQueries({queryKey: ['researchInstructions']})
      if (showToast) successToast('Research Instructions Updated!')
    },
    onError: () => {
      errorToast('Updating Research Instructions Failed.\nPlease try again later.')
    }
  })
}
export const useDeleteResearchInstructions = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({params}) => agentApi.deleteResearchInstructions({params}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['researchInstructions']})
      successToast('Research Instructions Deleted!')
    },
    onError: () => {
      errorToast('Deleting Research Instructions Failed.\nPlease try again later.')
    }
  })
}
export const useGetResearchResults = ({params} = {}) => {
  return useQuery({
    queryKey: ['researchResults', params],
    queryFn: () => agentApi.getResearchResults({params}),
    staleTime: 8 * 60 * 60 * 1000,
    cacheTime: 7 * 24 * 60 * 60 * 1000,
    meta: {errorMessage: 'Fetching Research Results Failed.\nPlease try again later.'}
  })
}
export const usePostResearchResults = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({payload}) => agentApi.postResearchResults({payload}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['researchResults']})
      successToast('Research Results Saved!')
    },
    onError: () => {
      errorToast('Saving Research Results Failed.\nPlease try again later.')
    }
  })
}
export const usePatchResearchResults = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({params, payload}) => agentApi.patchResearchResults({params, payload}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['researchResults']})
      successToast('Research Results Updated!')
    },
    onError: () => {
      errorToast('Updating Research Results Failed.\nPlease try again later.')
    }
  })
}
export const useDeleteResearchResults = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({params}) => agentApi.deleteResearchResults({params}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['researchResults']})
      successToast('Research Results Deleted!')
    },
    onError: () => {
      errorToast('Deleting Research Results Failed.\nPlease try again later.')
    }
  })
}

//MESSAGES
export const useGetMessageResults = ({params} = {}) => {
  return useQuery({
    queryKey: ['messages', params],
    queryFn: () => agentApi.getMessageResults({params}),
    staleTime: 8 * 60 * 60 * 1000,
    cacheTime: 7 * 24 * 60 * 60 * 1000,
    meta: {
      errorMessage: 'Fetching Messages Failed.\nPlease try again later.'
    }
  })
}
export const usePostMessageResults = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({payload}) => agentApi.postMessageResults({payload}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['messages']})
      successToast('Message Saved!')
    },
    onError: () => {
      errorToast('Saving Message Failed.\nPlease try again later.')
    }
  })
}
export const usePatchMessageResults = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({params, payload}) => agentApi.patchMessageResults({params, payload}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['messages']})
      successToast('Message Updated!')
    },
    onError: () => {
      errorToast('Message Update Failed.\nPlease try again later.')
    }
  })
}
export const useDeleteMessageResults = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({params}) => agentApi.deleteMessageResults({params}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['messages']})
      successToast('Message Deleted!')
    },
    onError: () => {
      errorToast('Message Deletion Failed.\nPlease try again later.')
    }
  })
}
export const usePostMessageResultsGenerate = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({payload}) => agentApi.postMessageResultsGenerate({payload}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['userCredit']})
      successToast('Message Generated!')
    },
    onError: (e) => {
      let message = 'Generating Message Failed.\nPlease try again later.'
      if (e?.response?.status === 402) {
        message = e.message
      }
      errorToast(message)
    }
  })
}
export const useGetMessageInstructions = ({params} = {}) => {
  return useQuery({
    queryKey: ['messageInstructions', params],
    queryFn: () => agentApi.getMessageInstructions({params}),
    staleTime: 8 * 60 * 60 * 1000,
    cacheTime: 7 * 24 * 60 * 60 * 1000,
    meta: {
      errorMessage: 'Fetching Message Instructions Failed.\nPlease try again later.'
    }
  })
}
export const usePostMessageInstructions = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({payload}) => agentApi.postMessageInstructions({payload}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['messageInstructions']})
      successToast('Message Instructions Saved!')
    },
    onError: () => {
      errorToast('Saving Message Instructions Failed.\nPlease try again later.')
    }
  })
}
export const usePatchMessageInstructions = ({
  showToast = true,
  invalidateQuery = true
} = {}) => {
  const queryClient = useQueryClient()
  return useMutation({
    //TODO add here update of messageInsutrcionsMetaDataAtom here to also set usedInCampaign to whatever the backend responds with
    mutationFn: ({params, payload}) =>
      agentApi.patchMessageInstructions({params, payload}),
    onSuccess: () => {
      if (invalidateQuery) {
        queryClient.invalidateQueries({queryKey: ['messageInstructions']})
      }
      if (showToast) {
        successToast('Message Instructions Updated!')
      }
    },
    onError: () => {
      errorToast('Updating Message Instructions Failed.\nPlease try again later.')
    }
  })
}
export const useDeleteMessageInstructions = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({params}) => agentApi.deleteMessageInstructions({params}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['messageInstructions']})
      successToast('Message Instructions Deleted!')
    },
    onError: () => {
      errorToast('Deleting Message Instructions Failed.\nPlease try again later.')
    }
  })
}
