import {useState} from 'react'

//Atoms
import {InputGroup, Modal, Form, Col, Row, FloatingLabel} from 'react-bootstrap'
import {Button} from '../atoms/Button.js'
import {Spinner} from '../atoms/Spinner.js'

//MOLECULES
import {SelectCreateOptions} from '../molecules/SelectCreateOptions.js'

export const SaveResearchInstructionsDialog = ({
  allInputIsCorrect,
  onHide,
  open,
  patchResearchInstructions,
  patchResearchInstructionsStatus,
  postResearchInstructions,
  postResearchInstructionsStatus,
  researchInstructions,
  researchInstructionsAtom,
  researchInstructionsFetchStatus,
  setResearchInstructionsAtomKey
}) => {
  const researchInstructionsOptions = researchInstructions?.map((m) => ({
    label: m?.label,
    value: m?.research_instructions_id
  }))
  const researchInstructionsExist = researchInstructions?.some(
    (researchInstruction) =>
      researchInstruction?.label === researchInstructionsAtom?.label
  )

  return (
    <Modal
      show={open}
      size='lg'
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>Manage Your Instructions</Modal.Header>
      <Modal.Body>
        <Form className='d-form'>
          <InputGroup as={Row}>
            <Col>
              <SelectCreateOptions
                options={researchInstructionsOptions}
                optionsLoading={researchInstructionsFetchStatus === 'fetching'}
                onChange={(e) => {
                  setResearchInstructionsAtomKey('label', e?.label)
                  setResearchInstructionsAtomKey('researchInstructionsId', e?.value)
                }}
                value={{
                  value: researchInstructionsAtom?.researchInstructionsId || '',
                  label: researchInstructionsAtom?.label
                }}
              />
            </Col>
            <Form.Group
              as={Col}
              xs='auto'
            >
              {researchInstructionsExist ? (
                <Button
                  children={'Update'}
                  onClick={patchResearchInstructions}
                  disabled={!allInputIsCorrect}
                  disabledText={
                    !allInputIsCorrect ? 'Please review your instructions' : null
                  }
                  isLoading={patchResearchInstructionsStatus === 'pending'}
                />
              ) : (
                <Button
                  children={'Save'}
                  disabledText={
                    !allInputIsCorrect
                      ? 'Please review your instructions'
                      : !researchInstructionsAtom?.label ||
                        researchInstructionsAtom?.label.length < 1
                      ? 'Saving your instructions requires providing a name'
                      : null
                  }
                  disabled={
                    !allInputIsCorrect ||
                    !researchInstructionsAtom?.label ||
                    researchInstructionsAtom?.label?.length < 1
                  }
                  onClick={postResearchInstructions}
                  isLoading={postResearchInstructionsStatus === 'pending'}
                />
              )}
            </Form.Group>
          </InputGroup>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export const LoadResearchInstructionsDialog = ({
  handleLoadResearchInstructions,
  researchInstructions,
  researchInstructionsFetchStatus,
  onHide,
  open
}) => {
  const [selectedResearchInstructions, setSelectedResearchInstructions] = useState(null)

  return (
    <Modal
      show={open}
      size='lg'
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>Load Instructions</Modal.Header>
      <Modal.Body>
        <Form className='d-form'>
          <Form.Group as={Row}>
            <Col
              className={
                researchInstructionsFetchStatus === 'fetching'
                  ? 'd-flex justify-content-center align-items-center'
                  : 'd-flex justify-content-stretch align-items-stretch'
              }
              style={{padding: 0}}
            >
              {researchInstructionsFetchStatus === 'fetching' ? (
                <Spinner />
              ) : (
                <FloatingLabel
                  label='Select Research Instructions'
                  style={{width: '100%'}}
                >
                  <Form.Select
                    value={selectedResearchInstructions || 'default'}
                    onChange={(e) => setSelectedResearchInstructions(e?.target?.value)}
                  >
                    {researchInstructions?.map((i) => (
                      <option
                        key={i.label}
                        value={i.research_instructions_id}
                      >
                        {i.label}
                      </option>
                    ))}
                    <option
                      key={'default'}
                      value={'default'}
                    >
                      Select an option
                    </option>
                  </Form.Select>
                </FloatingLabel>
              )}
            </Col>

            <Col
              xs={'auto'}
              className='d-flex align-items-center justify-content-center'
              style={{marginLeft: '1rem'}}
            >
              <Button
                children={'Load'}
                disabled={
                  !selectedResearchInstructions ||
                  selectedResearchInstructions === 'default'
                }
                disabledText={'Please select a valid option.'}
                onClick={() => {
                  handleLoadResearchInstructions(selectedResearchInstructions)
                  onHide()
                }}
              />
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export const SaveResearchResultsDialog = ({
  show,
  onHide,
  setResearchResultsAtomKey,
  researchResultsAtom,
  handlePostResearchResults,
  postResearchResultsStatus
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size='lg'
      centered
    >
      <Modal.Header closeButton>Save Research Results</Modal.Header>
      <Modal.Body>
        <Form className='d-form'>
          <InputGroup>
            <Form.Control
              value={researchResultsAtom?.label || ''}
              onChange={(e) => setResearchResultsAtomKey('label', e.target.value)}
              placeholder={'Save Research Results As...'}
            />
            <Button
              onClick={handlePostResearchResults}
              children='Save'
              className='inputFieldButton'
              disabled={!researchResultsAtom?.label}
              isLoading={postResearchResultsStatus === 'pending'}
            />
          </InputGroup>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
