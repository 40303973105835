import {Container, Col, Row} from 'react-bootstrap'
import {Paper} from '@mui/material'
import {InfoPageTemplate} from '../../templates/InfoPageTemplate.js'
import {useBreakpoints} from '../../../utils/helpers.js'

export const Contact = () => {
  const {isXs, isSm} = useBreakpoints()

  const contentColClass = 'd-flex flex-column justify-content-center align-items-center'
  const contentColBreakpoints = {xxl: 8, xl: 10, md: 11, xs: 12}

  const sections = [
    {
      icon: 'handshake',
      header: 'Sales Inquiries',
      text: 'Looking to supercharge your sales process with Pitchr? Reach out to our sales team to see how we can tailor our solutions to fit your needs.'
    },
    {
      icon: 'contact_support',
      header: 'Customer Support',
      text: 'Already a user? Need help with something? Our support team is ready to assist you with any issues or questions you may have.'
    },
    {
      icon: 'info',
      header: 'General Inquiries',
      text: 'For all other questions, comments, or if you just want to chat, feel free to drop us a line. We’re always happy to connect.'
    }
  ]

  return (
    <InfoPageTemplate>
      <Container
        id='contact-us'
        fluid
        className='background--p-light d-flex justify-content-center align-items-center'
        style={{paddingTop: '13rem', paddingBottom: '13rem'}}
      >
        <Col
          {...contentColBreakpoints}
          className={contentColClass}
        >
          <h1 className='d-text__font--heading text__placement--center color--p-dark d-flex align-items-center'>
            Contact Us
          </h1>
          <h3
            style={{padding: '1rem', textAlign: 'center'}}
            className='d-text__font--subheading color--s-dark'
          >
            We'd love to hear from you!
          </h3>
          <Row className='d-flex justify-content-around align-items-center'>
            <Col
              xxl={6}
              md={7}
              sm={8}
              xs={11}
            >
              {sections.map(({icon, header, text}, idx) => (
                <>
                  <h4
                    style={{padding: '1rem 0 0.25rem 0.5rem'}}
                    className='d-text__font--subheading color--s-dark d-flex align-items-center'
                    key={idx}
                  >
                    <span
                      className='material-symbols-outlined color--accent'
                      style={{
                        marginRight: '0.5rem',
                        fontSize: 'inherit',
                        lineHeight: 'inherit',
                        height: 'inherit',
                        verticalAlign: 'middle'
                      }}
                    >
                      {icon}
                    </span>
                    {header}
                  </h4>
                  <p
                    className='d-text__font--body color--p-dark'
                    style={{textWrap: 'pretty'}}
                  >
                    {text}
                  </p>
                </>
              ))}
            </Col>
            <Col
              className='d-flex flex-column justify-content-center align-items-center'
              xs={13}
              sm={'auto'}
            >
              <Paper
                className='background--s-light d-flex flex-column justify-content-center align-items-center'
                elevation={1}
                style={{
                  padding: '2.5rem 5rem 2.5rem 5rem',
                  marginTop: isXs || isSm ? '2rem' : 0
                }}
              >
                <h3
                  style={{padding: '1rem', whiteSpace: 'nowrap'}}
                  className='d-text__font--subheading d-text__placement--center color--p-dark'
                >
                  Get in Touch
                </h3>
                <div className='d-flex flex-column justify-content-start align-items-start'>
                  <p className='d-text__font--body color--p-dark d-flex align-items-center'>
                    <span
                      className='material-symbols-outlined color--accent clickable'
                      style={{
                        marginRight: '0.5rem',
                        fontSize: 'inherit',
                        lineHeight: 'inherit',
                        height: 'inherit',
                        verticalAlign: 'middle'
                      }}
                      onClick={() =>
                        (window.location.href = 'mailto:contact@pitchr.co')
                      }
                    >
                      mail
                    </span>
                    contact@pitchr.co
                  </p>
                  <p className='d-text__font--body color--p-dark d-flex align-items-center'>
                    <span
                      className='material-symbols-outlined color--accent'
                      style={{
                        marginRight: '0.5rem',
                        fontSize: 'inherit',
                        lineHeight: 'inherit',
                        height: 'inherit',
                        verticalAlign: 'middle'
                      }}
                    >
                      call
                    </span>
                    +31 647 747 164
                  </p>
                </div>
              </Paper>
            </Col>
          </Row>
        </Col>
      </Container>
    </InfoPageTemplate>
  )
}
