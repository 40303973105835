import {useState, useRef} from 'react'
import CreatableSelect from 'react-select/creatable'
import {Spinner} from '../atoms/Spinner.js'

const LoadingIndicator = () => (
  <>
    <Spinner
      animation='grow'
      size='sm'
    />
    <span style={{marginRight: '10px'}}></span>
  </>
)

const colors = {
  lightAccent: 'var(--secondary-light)',
  primaryLight: 'var(--primary-light)',
  accent: 'var(--accent)',
  primaryDark: 'var(--primary-dark)'
}

const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: colors.primaryLight,
    boxShadow: 'none',
    '&:hover': {
      borderColor: 'var(--accent)'
    },
    '&:focus-within': {
      borderColor: 'var(--accent)'
    }
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: colors.primaryLight,
    color: colors.primaryDark
  }),
  menuPortal: (base) => ({...base, zIndex: 9999}),
  option: (styles, {isFocused, data}) => {
    const isCreate = data.__isNew__
    const backgroundColor = isFocused ? colors.accent : colors.primaryLight
    const color = isFocused
      ? colors.primaryLight
      : isCreate
      ? colors.accent
      : colors.primaryDark
    const active = {
      color: colors.primaryLight,
      backgroundColor: colors.accent,
      zIndex: 999
    }
    const textDecoration = isCreate ? 'underline' : 'none'

    return {
      ...styles,
      backgroundColor: backgroundColor,
      color: color,
      '&:active': active,
      textDecoration: textDecoration,
      fontSize: '0.833rem'
    }
  }
}

export const SelectCreateOptions = ({
  options,
  optionsLoading,
  onChange,
  value,
  placeholder = ''
}) => {
  const [inputValue, setInputValue] = useState('')
  const selectRef = useRef(null)

  const handleCreate = (input) => {
    onChange({label: input, value: input})
  }

  return (
    <CreatableSelect
      styles={customStyles}
      ref={selectRef}
      placeholder={placeholder}
      components={{LoadingIndicator}}
      isDisabled={optionsLoading || value?.length < 1}
      isLoading={optionsLoading}
      isClearable
      options={options}
      onChange={onChange}
      onCreateOption={handleCreate}
      onInputChange={(val) => setInputValue(val)}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && inputValue) {
          e.preventDefault()
          handleCreate(inputValue)
          selectRef.current.blur()
        }
      }}
      menuPortalTarget={document.body}
      value={value}
      noOptionsMessage={() => 'To add an option, please start typing'}
    />
  )
}
