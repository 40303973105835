import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Collapse} from 'react-bootstrap'
import {Paper, Box} from '@mui/material'
import {TabPanel, TabContext} from '@mui/lab'

import {AudienceModal} from '../organisms/AudienceModals.js'
import {MessageInstructionsModal} from '../organisms/MessageInstructionsModals.js'
import {MessageModal} from '../organisms/MessageModals.js'
import {ResourcesContainer} from '../organisms/SearchResultsContainers.js'
import {DynamicTabList} from '../organisms/DynamicTabList.js'

import {SidebarTemplate} from '../templates/SidebarTemplate.js'

import {
  useGetMessageInstructions,
  useGetMessageResults,
  useDeleteMessageInstructions,
  useDeleteMessageResults
} from '../../hooks/server/useAgentServerState.js'
import {
  useGetAudiences,
  useDeleteAudience
} from '../../hooks/server/useAudiencesServerState.js'
import {useGetCampaigns} from '../../hooks/server/useCampaignsServerState.js'

import {
  useAudienceMetaData,
  usePersonSearchSettings,
  useCompanySearchSettings
} from '../../hooks/client/useAudiencesClientState.js'
import {useMessageInstructionsAtom} from '../../hooks/client/useAgentClientState.js'

export const Dashboard = () => {
  //QUERY SERVER STATES
  const {data: audiences, fetchStatus: audiencesFetchStatus} = useGetAudiences()
  const {data: messageInstructions, fetchStatus: messageInstructionsFetchStatus} =
    useGetMessageInstructions()
  const {data: messages, fetchStatus: messagesFetchStatus} = useGetMessageResults()
  const {data: campaigns, fetchStatus: campaignsFetchStatus} = useGetCampaigns({
    params: {per_page: 10000, page: 0}
  })

  //MUTATE SERVER STATES
  const {mutate: deleteMessageInstructions, status: deleteMessageInstructionsStatus} =
    useDeleteMessageInstructions()
  const {mutate: deleteMessageResults, status: deleteMessageStatus} =
    useDeleteMessageResults()
  const {mutate: deleteAudience, status: deleteAudienceStatus} = useDeleteAudience()

  //GLOBAL UI STATES
  const {setAudienceMetaDataAtom, audienceMetaDataFromResponse} = useAudienceMetaData()
  const {setPersonSearchSettingsAtom, personSearchSettingsFromResponse} =
    usePersonSearchSettings()
  const {setCompanySearchSettingsAtom, companySearchSettingsFromResponse} =
    useCompanySearchSettings()
  const {setMessageInstructionsAtom} = useMessageInstructionsAtom()

  //LOCAL UI STATES
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('0')
  const [isMessage, setIsMessage] = useState(true)

  const [showMessageInstructionsModal, setShowMessageInstructionsModal] =
    useState(false)
  const [showMessageModal, setShowMessageModal] = useState(false)
  const [showAudienceModal, setShowAudienceModal] = useState(false)

  const [activeMessageInstructions, setActiveMessageInstructions] = useState(null)
  const [activeMessage, setActiveMessage] = useState(null)
  const [activeAudience, setActiveAudience] = useState(null)

  //VARIABLES
  const tabsData = [
    {
      value: '0',
      iconName: 'campaign',
      tabName: 'Campaign',
      isLoading: campaignsFetchStatus === 'fetching'
    },
    {
      value: '1',
      iconName: 'group',
      tabName: 'Audience',
      isLoading:
        audiencesFetchStatus === 'fetching' || deleteAudienceStatus === 'pending'
    },
    {
      value: '2',
      iconName: 'message',
      tabName: 'Message',
      isLoading:
        messagesFetchStatus === 'fetching' ||
        deleteMessageStatus === 'pending' ||
        messageInstructionsFetchStatus === 'fetching' ||
        deleteMessageInstructionsStatus === 'pending'
    }
  ]

  return (
    <>
      <AudienceModal
        show={showAudienceModal}
        onHide={() => {
          setShowAudienceModal(false)
          setActiveAudience(null)
        }}
        audienceMetaDataAtom={audienceMetaDataFromResponse(activeAudience)}
        personSearchSettingsAtom={personSearchSettingsFromResponse(activeAudience)}
        companySearchSettingsAtom={companySearchSettingsFromResponse(activeAudience)}
        onDelete={() => {
          setShowAudienceModal(false)
          setActiveAudience(null)
          deleteAudience({
            params: {audience_id: activeAudience?.audience_id}
          })
        }}
        onEdit={() => {
          setCompanySearchSettingsAtom(
            companySearchSettingsFromResponse(activeAudience)
          )
          setPersonSearchSettingsAtom(personSearchSettingsFromResponse(activeAudience))
          setAudienceMetaDataAtom(audienceMetaDataFromResponse(activeAudience))
          setShowAudienceModal(false)
          navigate(`/audience-builder`)
        }}
      />
      <MessageModal
        show={showMessageModal}
        onHide={() => {
          setShowMessageModal(false)
          setActiveMessage(null)
        }}
        label={activeMessage?.label}
        emailSubject={activeMessage?.message_header || ''}
        emailBody={activeMessage?.message_body || ''}
        updatedAt={activeMessage?.updated_at || ''}
        onDelete={() => {
          setShowMessageModal(false)
          setActiveMessage(null)
          deleteMessageResults({
            params: {message_id: activeMessage?.message_id}
          })
        }}
      />
      <MessageInstructionsModal
        show={showMessageInstructionsModal}
        onHide={() => {
          setShowMessageInstructionsModal(false)
          setActiveMessageInstructions(null)
        }}
        label={activeMessageInstructions?.label || ''}
        title={activeMessageInstructions?.title || ''}
        companyName={activeMessageInstructions?.company_name || ''}
        intention={activeMessageInstructions?.intention || ''}
        language={activeMessageInstructions?.language || ''}
        outreachMethod={activeMessageInstructions?.outreach_method || ''}
        outreachStage={activeMessageInstructions?.outreach_stage || ''}
        messageSignature={activeMessageInstructions?.message_signature || ''}
        preferences={activeMessageInstructions?.message_preferences || []}
        usedInCampaign={activeMessageInstructions?.used_in_campaign || false}
        valueProposition={activeMessageInstructions?.value_proposition || ''}
        updatedAt={activeMessageInstructions?.updated_at || ''}
        onDelete={() => {
          setShowMessageInstructionsModal(false)
          setActiveMessageInstructions(null)
          deleteMessageInstructions({
            params: {
              message_instructions_id:
                activeMessageInstructions?.message_instructions_id
            }
          })
        }}
        onEdit={() => {
          setMessageInstructionsAtom({
            messageInstructionsId: activeMessageInstructions?.message_instructions_id,
            label: activeMessageInstructions?.label,
            language: activeMessageInstructions?.language,
            title: activeMessageInstructions?.title,
            companyName: activeMessageInstructions?.company_name,
            valueProposition: activeMessageInstructions?.value_proposition,
            intention: activeMessageInstructions?.intention,
            preferences: activeMessageInstructions?.message_preferences,
            outreachMethod: activeMessageInstructions?.outreach_method,
            outreachStage: activeMessageInstructions?.outreach_stage
          })
          setShowMessageInstructionsModal(false)
          navigate(`/message-builder`)
        }}
      />
      <SidebarTemplate>
        <Paper
          className='d-flex flex-column align-items-center background--p-light'
          variant='outlined'
          style={{padding: '1rem', borderRadius: '1rem', margin: '2rem'}}
        >
          <Box sx={{width: '100%'}}>
            <TabContext value={activeTab}>
              <DynamicTabList
                tabs={tabsData}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
              <TabPanel
                value={'0'}
                style={{margin: 0, padding: 0, maxHeight: '70vh', overflowY: 'auto'}}
              >
                <ResourcesContainer
                  results={campaigns?.items}
                  iconName={'campaign'}
                  fieldMapping={{label: 'campaign_name'}}
                  onClick={(campaign) => {
                    navigate(`/campaign-insights/?campaign_id=${campaign?.campaign_id}`)
                  }}
                />
              </TabPanel>
              <TabPanel
                value={'1'}
                style={{margin: 0, padding: 0, maxHeight: '70vh', overflowY: 'auto'}}
              >
                <ResourcesContainer
                  results={audiences}
                  iconName={'group'}
                  onClick={(audience) => {
                    setActiveAudience(audience)
                    setShowAudienceModal(true)
                  }}
                />
              </TabPanel>
              <TabPanel
                value={'2'}
                className='d-flex flex-column'
                style={{margin: 0, padding: 0}}
              >
                <p
                  style={{margin: '1rem 1rem 0 1rem'}}
                  className='d-flex align-items-center color--s-dark'
                >
                  <span
                    onClick={() => setIsMessage(false)}
                    className={`${
                      isMessage
                        ? 'clickable transition-fast t-color--accent'
                        : 'color--accent'
                    }`}
                    style={{marginRight: '0.2rem'}}
                  >
                    Instructions
                  </span>
                  |
                  <span
                    onClick={() => setIsMessage(true)}
                    className={`${
                      isMessage
                        ? 'color--accent'
                        : 'clickable transition-fast t-color--accent'
                    }`}
                    style={{marginLeft: '0.2rem'}}
                  >
                    Messages
                  </span>
                </p>

                <Collapse
                  in={!isMessage}
                  mountOnEnter
                  unmountOnExit
                >
                  <div style={{maxHeight: '70vh', overflowY: 'auto'}}>
                    <ResourcesContainer
                      results={messageInstructions}
                      iconName={'message'}
                      onClick={(messageInstruction) => {
                        setActiveMessageInstructions(messageInstruction)
                        setShowMessageInstructionsModal(true)
                      }}
                    />
                  </div>
                </Collapse>
                <Collapse
                  in={isMessage}
                  mountOnEnter
                  unmountOnExit
                >
                  <div style={{maxHeight: '70vh', overflowY: 'auto'}}>
                    <ResourcesContainer
                      results={messages}
                      iconName={'send'}
                      onClick={(message) => {
                        setActiveMessage(message)
                        setShowMessageModal(true)
                      }}
                    />
                  </div>
                </Collapse>
              </TabPanel>
            </TabContext>
          </Box>
        </Paper>
      </SidebarTemplate>
    </>
  )
}
