import {atom} from 'recoil'
import {sessionStorageEffect} from './effects.js'

//ATOMS
export const researchInstructions = atom({
  key: 'researchInstructions',
  default: {
    researchInstructionsId: null,
    label: null,
    intention: null,
    updatedAt: null,
    createdAt: null,
    preferences: [],
    questions: []
  },
  effects: [sessionStorageEffect('researchInstructions')]
})

export const researchResults = atom({
  key: 'researchResults',
  default: {
    researchResultId: null,
    researchInstructionsId: null,
    label: null,
    result: null,
    companyLinkedinUrl: null,
    updatedAt: null,
    createdAt: null
  },
  effects: [sessionStorageEffect('researchResults')]
})
