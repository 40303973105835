import {useRecoilState} from 'recoil'
import {
  companySearchSettings,
  personSearchSettings,
  audienceMetaData
} from '../../states/audiences.js'

export const useAudienceMetaData = () => {
  const [audienceMetaDataAtom, setAudienceMetaDataAtom] =
    useRecoilState(audienceMetaData)

  const setAudienceMetaDataKey = (key, value) => {
    setAudienceMetaDataAtom((_) => ({..._, [key]: value}))
  }

  const audienceMetaDataFromResponse = (audience) => {
    return {
      label: audience?.label,
      companiesInAudience: audience?.companies_in_audience,
      peopleInAudience: audience?.people_in_audience,
      createdAt: audience?.created_at,
      updatedAt: audience?.updated_at,
      usedInCampaign: audience?.usedInCampaign,
      audienceId: audience?.audience_id
    }
  }

  return {
    audienceMetaDataAtom,
    setAudienceMetaDataAtom,
    setAudienceMetaDataKey,
    audienceMetaDataFromResponse
  }
}

export const usePersonSearchSettings = () => {
  const [personSearchSettingsAtom, setPersonSearchSettingsAtom] =
    useRecoilState(personSearchSettings)

  const setPersonSearchSettingsKey = (key, value) => {
    setPersonSearchSettingsAtom((_) => ({..._, [key]: value}))
  }

  const personSearchSettingsFromResponse = (audience) => {
    return {
      searchOrganizations: audience?.person_search_filters?.search_organizations || [],
      countryValues: audience?.person_search_filters?.countries || [],
      seniorityValues: audience?.person_search_filters?.seniorities || [],
      departmentValues: audience?.person_search_filters?.departments || [],
      keywordValues: audience?.person_search_filters?.keywords || []
    }
  }

  return {
    personSearchSettingsAtom,
    setPersonSearchSettingsAtom,
    setPersonSearchSettingsKey,
    personSearchSettingsFromResponse
  }
}

export const useCompanySearchSettings = () => {
  const [companySearchSettingsAtom, setCompanySearchSettingsAtom] =
    useRecoilState(companySearchSettings)

  const setCompanySearchSettingsKey = (key, value) => {
    setCompanySearchSettingsAtom((_) => ({..._, [key]: value}))
  }

  const companySearchSettingsFromResponse = (audience) => {
    return {
      employeesValues: audience?.company_search_filters?.totalEmployees?.values || [],
      employeesSign: audience?.company_search_filters?.totalEmployees?.sign || 'equals',
      employeesOperator:
        audience?.company_search_filters?.totalEmployees?.operator || 'or',

      cityValues: audience?.company_search_filters?.city_code?.values || [],
      citySign: audience?.company_search_filters?.city_code?.sign || 'equals',
      cityOperator: audience?.company_search_filters?.city_code?.operator || 'or',

      countyValues: audience?.company_search_filters?.county_code?.values || [],
      countySign: audience?.company_search_filters?.county_code?.sign || 'equals',
      countyOperator: audience?.company_search_filters?.county_code?.operator || 'or',

      stateValues: audience?.company_search_filters?.state_code?.values || [],
      stateSign: audience?.company_search_filters?.state_code?.sign || 'equals',
      stateOperator: audience?.company_search_filters?.state_code?.operator || 'or',

      continentValues: audience?.company_search_filters?.continent_code?.values || [],
      continentSign: audience?.company_search_filters?.continent_code?.sign || 'equals',
      continentOperator:
        audience?.company_search_filters?.continent_code?.operator || 'or',

      countryValues: audience?.company_search_filters?.country_code?.values || [],
      countrySign: audience?.company_search_filters?.country_code?.sign || 'equals',
      countryOperator: audience?.company_search_filters?.country_code?.operator || 'or',

      revenueValues: audience?.company_search_filters?.revenue?.values || [],
      revenueSign: audience?.company_search_filters?.revenue?.sign || 'equals',
      revenueOperator: audience?.company_search_filters?.revenue?.operator || 'or',

      industriesValues: audience?.company_search_filters?.industries?.values || [],
      industriesSign: audience?.company_search_filters?.industries?.sign || 'equals',
      industriesOperator:
        audience?.company_search_filters?.industries?.operator || 'or',

      industryValues: audience?.company_search_filters?.industryMain?.values || [],
      industrySign: audience?.company_search_filters?.industryMain?.sign || 'equals',
      industryOperator:
        audience?.company_search_filters?.industryMain?.operator || 'or',

      codeNaicsValues: audience?.company_search_filters?.codeNaics?.values || [],
      codeNaicsSign: audience?.company_search_filters?.codeNaics?.sign || 'exactEquals',
      codeNaicsOperator: audience?.company_search_filters?.codeNaics?.operator || 'or',

      technologyValues: audience?.company_search_filters?.technologies?.values || [],
      technologySign: audience?.company_search_filters?.technologies?.sign || 'equals',
      technologyOperator:
        audience?.company_search_filters?.technologies?.operator || 'or',

      linkedinIdValues: audience?.company_search_filters?.linkedinId?.values || [],
      linkedinIdSign: audience?.company_search_filters?.linkedinId?.sign || 'notEquals',
      linkedinIdOperator:
        audience?.company_search_filters?.linkedinId?.operator || 'or',

      domainValues: audience?.company_search_filters?.domain?.values || [],
      domainSign: audience?.company_search_filters?.domain?.sign || 'notEquals',
      domainOperator: audience?.company_search_filters?.domain?.operator || 'or',

      nameValues: audience?.company_search_filters?.name?.values || [],
      nameSign: audience?.company_search_filters?.name?.sign || 'equals',
      nameOperator: audience?.company_search_filters?.name?.operator || 'or'
    }
  }

  return {
    companySearchSettingsAtom,
    setCompanySearchSettingsAtom,
    setCompanySearchSettingsKey,
    companySearchSettingsFromResponse
  }
}
