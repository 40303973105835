import {Stepper, Step, StepLabel, StepContent} from '@mui/material'
import {isTruthy} from '../../utils/hooks.js'
// example steps:
// [
//     {
//       header: 'Audience',
//       icon: 'group',
//       informationIsCorrect: true,
//		 onClick: example,
//     },
//     {
//       header: 'Details',
//       icon: 'bookmark',
//       informationIsCorrect: false,
//		 onClick: example2,
//     }
// ]
export const WorkflowStepper = ({steps, activeStep, setActiveStep}) => {
  return (
    <Stepper
      activeStep={activeStep}
      orientation={'vertical'}
    >
      {steps?.map((step, index) => (
        <Step
          key={index}
          onClick={() => {
            setActiveStep(index)
            if (isTruthy(step?.tabOnClick)) {
              step?.tabOnClick()
            }
          }}
        >
          <StepLabel
            StepIconComponent={() => (
              <span
                className={
                  activeStep === index
                    ? 'material-symbols-outlined color--accent'
                    : `material-symbols-outlined clickable transition-medium ${
                        step.informationIsCorrect
                          ? 'color--success'
                          : 'color--s-dark t-color--accent'
                      }`
                }
              >
                {step.informationIsCorrect ? 'check' : step.icon}
              </span>
            )}
          >
            <p
              className={
                activeStep === index
                  ? 'd-flex align-items-center d-text__font--heading d-text__placement--center color--accent clickable'
                  : 'd-flex align-items-center d-text__font--heading d-text__placement--center color--p-dark clickable transition-medium t-color--accent'
              }
              style={{textWrap: 'nowrap', textAlign: 'start'}}
            >
              {step.header}
            </p>
          </StepLabel>
          <StepContent>
            <span
              style={{padding: 0}}
              className=' d-flex align-items-center color--p-dark clickable transition-fast t-color--accent'
              onClick={(e) => {
                e.stopPropagation()
                index < steps.length - 1 ? setActiveStep(index + 1) : setActiveStep(0)
                if (isTruthy(step?.nextOnClick)) {
                  step?.nextOnClick()
                }
              }}
            >
              {index < steps.length - 1 ? 'Next' : 'Back'}
              <span className='material-symbols-outlined'>{'arrow_right_alt'}</span>
            </span>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  )
}
