import {useRecoilState} from 'recoil'
import {
  generatedMessageAtom,
  inputMessagesAtom,
  enrichmentPersonUrlAtom,
  enrichmentCompanyUrlAtom
} from '../../states/messages.js'
import {messageInstructions} from '../../states/messageInstructions.js'
import {researchInstructions, researchResults} from '../../states/research.js'

export const useResearchResultsAtom = () => {
  const [researchResultsAtom, setResearchResultsAtom] = useRecoilState(researchResults)

  const setResearchResultsAtomKey = (key, value) => {
    setResearchResultsAtom((_) => ({..._, [key]: value}))
  }

  const researchResultsFromResponse = (response) => {
    return {
      researchInstructionsId: response.research_instructions_id,
      researchResultId: response.research_result_id,
      label: response.label,
      result: response.result,
      companyLinkedinUrl: response.company_linkedin_url,
      updatedAt: response.updated_at,
      createdAt: response.created_at
    }
  }

  return {
    researchResultsAtom,
    setResearchResultsAtom,
    setResearchResultsAtomKey,
    researchResultsFromResponse
  }
}
export const useResearchInstructionsAtom = () => {
  const [researchInstructionsAtom, setResearchInstructionsAtom] =
    useRecoilState(researchInstructions)

  const setResearchInstructionsAtomKey = (key, value) => {
    setResearchInstructionsAtom((_) => ({..._, [key]: value}))
  }

  const researchInstructionsFromResponse = (response) => {
    return {
      researchInstructionsId: response.research_instructions_id,
      label: response.label,
      intention: response.intention,
      updatedAt: response.updated_at,
      createdAt: response.created_at,
      preferences: response.preferences,
      questions: response.questions
    }
  }

  return {
    researchInstructionsAtom,
    setResearchInstructionsAtom,
    setResearchInstructionsAtomKey,
    researchInstructionsFromResponse
  }
}

export const useGeneratedMessageAtom = () => {
  const [value, setValue] = useRecoilState(generatedMessageAtom)
  return [value, setValue]
}

export const useInputMessagesAtom = () => {
  const [inputMessages, setInputMessages] = useRecoilState(inputMessagesAtom)
  return {inputMessages, setInputMessages}
}

export const useEnrichmentPersonUrlAtom = () => {
  const [value, setValue] = useRecoilState(enrichmentPersonUrlAtom)
  return [value, setValue]
}

export const useEnrichmentCompanyUrlAtom = () => {
  const [value, setValue] = useRecoilState(enrichmentCompanyUrlAtom)
  return [value, setValue]
}

export const useMessageInstructionsAtom = () => {
  const [messageInstructionsAtom, setMessageInstructionsAtom] =
    useRecoilState(messageInstructions)

  const setMessageInstructionsKey = (key, value) => {
    setMessageInstructionsAtom((_) => ({..._, [key]: value}))
  }

  return {
    messageInstructionsAtom,
    setMessageInstructionsAtom,
    setMessageInstructionsKey
  }
}
