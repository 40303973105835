import {Container, Row, Col, Image} from 'react-bootstrap'
import {Paper} from '@mui/material'
import {Button} from '../../atoms/Button.js'

import {InfoPageTemplate} from '../../templates/InfoPageTemplate.js'

export const Demo = () => {
  const contentColClass = 'd-flex flex-column justify-content-center align-items-center'
  const contentColBreakpoints = {
    xxl: 8,
    xl: 10,
    md: 11,
    xs: 12
  }

  const bulletPoints = [
    'GDPR Compliant',
    'Find ready to buy prospects in new or existing markets',
    'Access key stakeholders contact information',
    'Get more time back on traditional prospecting and email writing'
  ]

  return (
    <InfoPageTemplate>
      <Container
        id='schedule'
        fluid
        className='background--p-light d-flex justify-content-center align-items-center'
        style={{paddingTop: '6.5rem', paddingBottom: '13rem'}}
      >
        <Col
          {...contentColBreakpoints}
          className={contentColClass}
        >
          <h3 className='d-text__font--subheading color--p-dark'>
            Pitchr works with email service providers you know
          </h3>
          <div>
            <Image
              src={
                'https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/brand-icons/product/svg/outlook_48x1.svg'
              }
              alt={'Outlook'}
              style={{height: '4rem', margin: '2rem'}}
            />
            <Image
              src={
                'https://lh3.googleusercontent.com/0rpHlrX8IG77awQMuUZpQ0zGWT7HRYtpncsuRnFo6V3c8Lh2hPjXnEuhDDd-OsLz1vua4ld2rlUYFAaBYk-rZCODmi2eJlwUEVsZgg'
              }
              alt={'Gmail'}
              style={{height: '4rem', margin: '2rem'}}
            />
          </div>

          <h5
            className='d-text__font--body color--s-dark'
            style={{paddingBottom: '6.5rem'}}
          >
            {'✓ Application Verified by '}
            <strong>Google</strong> & <strong>Microsoft</strong>
          </h5>

          <Paper
            elevation={4}
            style={{padding: '1rem'}}
            className='background--p-dark d-flex justify-content-center align-items-center'
          >
            <Row className='d-flex justify-content-center'>
              <Col
                xs={12}
                sm={8}
                lg={6}
              >
                <Paper
                  className='background--s-dark'
                  style={{padding: '3rem'}}
                  square={false}
                >
                  <h3
                    className='d-text__font--heading color--p-light'
                    style={{textWrap: 'balance'}}
                  >
                    See Pitchr In Action
                  </h3>
                  <h5
                    className='d-text__font--subheading color--s-light'
                    style={{textWrap: 'balance'}}
                  >
                    Schedule a demo first, to ensure you get the most out of our
                    platform
                  </h5>
                  {bulletPoints.map((text, i) => (
                    <p
                      key={i}
                      className='d-text__font--body color--p-light d-flex align-items-center'
                      style={{textWrap: 'balance', paddingTop: i === 0 ? '1rem' : ''}}
                    >
                      <span
                        className='material-symbols-outlined'
                        style={{
                          color: 'yellowgreen',
                          marginRight: '0.5rem',
                          fontSize: '1.44rem',
                          lineHeight: '1.44rem',
                          height: '1.44rem',
                          verticalAlign: 'middle'
                        }}
                      >
                        check
                      </span>
                      {text}
                    </p>
                  ))}
                </Paper>
              </Col>

              <Col
                style={{padding: '3rem'}}
                className='d-flex flex-column align-items-center justify-content-center'
              >
                <h3 className='d-text__font--subheading d-text__placement--center color--p-light'>
                  Ready To Scale Your Outreach?
                </h3>
                <Button
                  style={{marginTop: '1rem'}}
                  children={
                    <>
                      {'Schedule Demo'}
                      <span
                        className='material-symbols-outlined'
                        style={{marginLeft: '0.5rem'}}
                      >
                        calendar_month
                      </span>
                    </>
                  }
                  onClick={() =>
                    window.open(
                      'https://calendly.com/charlie-salesautomation/30min',
                      '_blank',
                      'noopener,noreferrer'
                    )
                  }
                  variant='outline-secondary'
                />
              </Col>
            </Row>
          </Paper>
        </Col>
      </Container>
    </InfoPageTemplate>
  )
}
